import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { DateFormattingForTimeExtent } from '../utils';
import { gridRow } from './GridRow';

interface TectonServingStatusAxisLabelProps {
  date: Date;
  range: Date[];
}

export const TectonServingStatusAxisLabel: FC<TectonServingStatusAxisLabelProps> = ({ date, range }) => {
  const { theme } = useTectonTheme();

  const DatePill = styled.div`
    display: flex;
    height: 20px;
    padding: 0px ${theme.padding.xs};
    margin: 16px 0px 8px 0px;
    align-items: center;
    gap: ${theme.padding.s};
    font-size: ${theme.font.fontSizes.xs};
    border: ${theme.border.thin};
    border-radius: ${theme.border.radius.large};
    box-shadow: ${theme.shadow.s};
    cursor: default;
  `;

  const formatter = DateFormattingForTimeExtent((range[1].getTime() - range[0].getTime()) / 1000);
  return <DatePill>{formatter(date)}</DatePill>;
};

interface ServingStatusAxisRowProps {
  startDate: Date;
}

const ServingStatusAxisRow: FC<ServingStatusAxisRowProps> = ({ startDate }) => {
  const { theme } = useTectonTheme();
  const currentDate = new Date();
  const GridRow = gridRow(theme);
  return (
    <GridRow>
      <div></div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <TectonServingStatusAxisLabel date={startDate} range={[startDate, currentDate]} />
        </div>
        <div>
          <TectonServingStatusAxisLabel date={currentDate} range={[startDate, currentDate]} />
        </div>
      </div>
      <div></div>
    </GridRow>
  );
};

export default ServingStatusAxisRow;
