import React from 'react';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from './core/feature-flag/selectors';
import { useFeatureFlagsHook } from './core/feature-flag/hooks';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CONFIG } from './utils/okta';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import Authenticator from './feature/auth/Authenticator';
import InitializationLoadingIndicator from './core/initialization/InitializationLoadingIndicator';
import '@elastic/eui/dist/eui_theme_light.css';
import { UserSettingsContextProvider } from './components/context/UserSettingsContext';
import { TimezoneContextProvider } from './components/context/TimezoneContext';
import FullStoryInit from './components/FullStoryInitializer';
import ProductFruitsInit from './components/ProductFruitsInitializer';
import AppWrapper from './AppWrapper';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1800000, // 30 min
    },
  },
});

let oktaAuth: OktaAuth;

const App = () => {
  useFeatureFlagsHook(); // Gets Feature Flags and plugs them into Redux Store

  oktaAuth = new OktaAuth(OKTA_CONFIG);
  oktaAuth.start();

  const featureFlags = useSelector(selectFeatureFlags);
  if (!featureFlags) {
    // Let's wait for featureFlags are set in Redux
    return <InitializationLoadingIndicator />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserSettingsContextProvider>
        <TimezoneContextProvider>
          <Authenticator oktaAuth={oktaAuth} oktaEnabled={featureFlags.oktaEnabled}>
            <AppWrapper />
            <ProductFruitsInit />
            <FullStoryInit />
          </Authenticator>
        </TimezoneContextProvider>
      </UserSettingsContextProvider>
    </QueryClientProvider>
  );
};

export default App;
