import { EuiSplitPanel } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';

interface SplitPanelProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

const LeftBody = styled.div((props) => ({
  padding: '0 ' + props.theme.padding.xl + ' 0 ' + '0',
  overflow: 'scroll',
}));

const RightBody = styled.div((props) => ({
  padding: '0px 0px 0px ' + props.theme.padding.xl,
  overflow: 'scroll',
  borderLeft: '1px solid ' + props.theme.colors.dataGridBorder,
}));

const SplitPanel: FC<SplitPanelProps> = ({ leftContent, rightContent }) => {
  return (
    <EuiSplitPanel.Outer direction="row" hasShadow={false} css={{ height: '100%' }}>
      <EuiSplitPanel.Inner paddingSize="none" className="eui-yScroll">
        <LeftBody>{leftContent}</LeftBody>
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner paddingSize="none" style={{ overflow: 'scroll' }} css={{ maxWidth: '50em' }}>
        <RightBody>{rightContent}</RightBody>
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  );
};

export default SplitPanel;
