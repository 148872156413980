import React, { FC } from 'react';
import { EuiCodeBlock } from '@elastic/eui';
import styled from '@emotion/styled';

export type CodeBlockLanguage = 'python' | 'javascript' | 'sql' | 'json' | 'bash' | 'diff';

interface TectonCodeProps {
  code: string;
  language?: CodeBlockLanguage;
}

const StyledCodeBlock = styled(EuiCodeBlock)`
  font-family: ${({ theme }) => theme.font.familyCode};
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${({ theme }) => theme.font.lineHeights.xs};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  width: 100%;
`;

const TectonCode: FC<TectonCodeProps> = ({ code, language }) => {
  return (
    <StyledCodeBlock isCopyable language={language || 'bash'} data-testid="code-block">
      {code}
    </StyledCodeBlock>
  );
};

export default TectonCode;
