import React, { useState } from 'react';
import { EuiBasicTable, EuiBasicTableColumn, Criteria, EuiTableSortingType } from '@elastic/eui';
import styled from '@emotion/styled';
import { EmptyPrompt } from '@tecton/ComponentRedesign';
import { FlexGroup, FlexItem, Icon } from '../../_eui_components';
import { clone } from 'lodash';
export type RowPropsCallback<T> = (row: T) => object;

interface TectonBasicTableProps<T, S> {
  items: T[];
  itemId?: string;
  itemIdToExpandedRowMap?: Record<string, React.ReactNode>;
  columns: EuiBasicTableColumn<T>[];
  sorting?: EuiTableSortingType<S>;
  onChange?: (s: Criteria<T>) => void;
  onChangePage?: () => void;
  pagination?: any;
  loading?: boolean;
  fetching?: boolean;
  isExpandable?: boolean;
  rowProps?: object | RowPropsCallback<T>;
  showPerPageOptions?: boolean | undefined;
  error?: string;
  className?: string;
  layout?: 'fixed' | 'auto';
  loadingText?: string;
  scrollable?: boolean;
  compressed?: boolean;
  allowWordBreak?: boolean;
  emptyPrompt?: React.ReactNode;
}

const StyledTable = styled.div<{
  allowWordBreak: boolean;
  compressed: boolean;
  length: number;
}>`
  width: 100%;
  overflow: hidden;

  * {
    white-space: ${(props) => (props.allowWordBreak ? 'wrap-word' : 'nowrap !important;')};
    word-break: ${(props) => (props.allowWordBreak ? 'break-word' : 'keep-all !important')};
  }

  position: relative;
  overflow: scroll;

  th {
    background-color: ${(props) => props.theme.colors.body};
    text-align: left;
    padding: ${(props) => (props.compressed ? 0 : `0px ${props.theme.padding.l}`)};
  }

  th:last-child {
    border-top-right-radius: ${(props) => props.theme.border.radius.medium};
  }

  th:first-child {
    border-top-left-radius: ${(props) => props.theme.border.radius.medium};
  }

  table th:first-child,
  tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }

  td {
    padding: ${(props) => (props.compressed ? 0 : `${props.theme.padding.l}`)};
    background-color: ${(props) => props.theme.colors.emptyShade};

    .euiTableCellContent {
      .euiToolTipAnchor {
        width: 100%;
      }
    }
  }

  .euiTableRow-isExpandedRow {
    td {
      padding: 0;
    }

    .euiTableCellContent {
      padding: 0;
    }
  }

  th {
    .euiTableCellContent__text {
      color: ${({ theme }) => theme.colors.text};
      font-weight: ${({ theme }) => theme.font.weight.regular};
      text-decoration: none;
    }

    .euiTableHeaderButton-isSorted {
      .euiTableCellContent__text {
        color: ${({ theme }) => theme.colors.title};
        font-weight: ${({ theme }) => theme.font.weight.medium};
        text-decoration: none;
      }
    }
  }

  // Account for empty space where the sort icon could be to ensure that table layouts don't change when sort is applied
  .euiTableHeaderButton:not(.euiTableHeaderButton-isSorted) {
    .euiTableCellContent__text {
      margin-right: 20px;
    }
  }

  th:hover {
    .euiTableCellContent__text {
      color: ${({ theme }) => theme.colors.title};
      text-decoration: none;
    }
  }

  .euiTableSortIcon {
    path {
      fill: ${({ theme }) => theme.colors.title};
    }
  }

  ${(props) =>
    props.length > 0 &&
    `tr:hover {
        td {
          background-color: ${props.theme.colors.lightestShade};
          button.euiButtonIcon {
            background-color: none;
          }
        }
      }

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: ${props.theme.border.radius.medium};
        }
        td:last-child {
          border-bottom-right-radius: ${props.theme.border.radius.medium};
        }
      }`}

  ${(props) =>
    props.length === 0 &&
    `td {
        border: none;
        padding-bottom:${props.theme.padding.xl}
      }

      overflow:visible;
      `}
`;

export const Table = <T, S>({
  items,
  itemId,
  itemIdToExpandedRowMap,
  columns,
  sorting,
  onChange,
  isExpandable,
  allowWordBreak,
  compressed = true,
  loading,
  fetching,
  rowProps,
  error,
  className,
  layout,
  loadingText = 'Loading',
  emptyPrompt,
  scrollable,
}: TectonBasicTableProps<T, S>) => {
  if (error) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt
            variant="storm"
            orientation="horizontal"
            shadow
            title={<>Something went wrong!</>}
            body={<></>}
          />
        </FlexItem>
      </FlexGroup>
    );
  }

  if (loading) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt variant="loading" orientation="vertical" title={loadingText} body={<></>} />
        </FlexItem>
      </FlexGroup>
    );
  }

  const addCaretColumnToTable = (columns: EuiBasicTableColumn<T>[]) => {
    let transformedColumns: EuiBasicTableColumn<T>[] = clone(columns);

    if (isExpandable) {
      transformedColumns = [
        {
          name: ' ',
          render: (row: any) => {
            if (Object.keys(itemIdToExpandedRowMap!).includes(row[itemId!])) {
              return <Icon type="arrowDown" className="no-stroke" />;
            }

            return (
              <>
                <Icon type="arrowRight" className="no-stroke" />
              </>
            );
          },
        },
        ...transformedColumns,
      ];
    }

    return transformedColumns.map((column) => {
      return {
        ...column,
      };
    });
  };

  return (
    <StyledTable allowWordBreak={!!allowWordBreak} length={items?.length} compressed={compressed}>
      <div className={`${scrollable ? 'eui-xScroll' : ''}`} data-testid="tecton-table-wrapper">
        <EuiBasicTable
          items={items}
          itemId={itemId}
          isExpandable={isExpandable}
          loading={fetching}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
          columns={addCaretColumnToTable(columns)}
          className={className}
          sorting={sorting as EuiTableSortingType<T> | undefined}
          onChange={(sort) => {
            onChange && onChange(sort);
          }}
          rowProps={rowProps}
          tableLayout={layout ? layout : 'auto'}
          noItemsMessage={
            emptyPrompt ?? (
              <EmptyPrompt
                title={<>No Results</>}
                body={
                  <>
                    <p>This request returned no results. Try a different time frame or set of filters.</p>
                  </>
                }
              />
            )
          }
        />
      </div>
    </StyledTable>
  );
};

export const useTableRowExpansionState = <T,>(
  rowIdAccessor: (row: T) => string,
  expansionRenderer: (row: T) => React.ReactNode
) => {
  const [itemIdToExpandedRowMap, setMapOfExpandedItems] = useState<Record<string, React.ReactNode>>({});

  const expandItemWithContent = (row: T) => {
    setMapOfExpandedItems({
      ...itemIdToExpandedRowMap,
      [rowIdAccessor(row)]: expansionRenderer(row),
    });
  };

  const collapseItem = (row: T) => {
    const updatedItems: Record<string, React.ReactNode> = {};

    Object.keys(itemIdToExpandedRowMap)
      .filter((key) => {
        return key !== rowIdAccessor(row);
      })
      .forEach((key) => {
        updatedItems[`${key}`] = itemIdToExpandedRowMap[key];
      });

    setMapOfExpandedItems(updatedItems);
  };

  const collapseAllItems = () => {
    setMapOfExpandedItems({});
  };

  const toggleRowExpansion = (row: T) => {
    if (itemIdToExpandedRowMap[rowIdAccessor(row)]) {
      collapseItem(row);
    } else {
      expandItemWithContent(row);
    }
  };

  return {
    itemIdToExpandedRowMap,
    expandItemWithContent,
    collapseItem,
    collapseAllItems,
    toggleRowExpansion,
  };
};

export default Table;
