import React, { lazy, useEffect, useRef } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { useLocation, matchPath } from 'react-router-dom';
import { Routes as AppRoutes } from '../../core/routes';
import useGlobalState from '../../shared/useGlobalState';
import useAppCuesInitializer from '../AppCuesInitializer';
import { PageNotFound } from '@tecton/ComponentRedesign';

const DataPlatformConfigEdit = lazy(() => import('./compute/DataPlatformConfigEdit'));
const ChoosePlatform = lazy(() => import('../../feature/onboarding/choose-platform/ChoosePlatform'));
const ConfigureDatabricksPage = lazy(() => import('../../feature/onboarding/configure/ConfigureDatabricksPage'));
const FeatureAnalytics = lazy(() => import('./featureAnalytics/FeatureAnalyticsContainer'));

const SetupInProgress = lazy(
  () => import('../../feature/onboarding/data-platform-setup/setup-in-progress/SetupInProgress')
);
const OnboardingRootRedirectPage = lazy(() => import('../../feature/onboarding/OnboardingRootRedirectPage'));

const WorkspaceSelectionWorkflow = lazy(
  () => import('../../core/component/workspace-selection/WorkspaceSelectionHandler')
);

const Workspace = lazy(() => import('../../core/component/WorkspaceOutlet'));

const Features = lazy(() => import('../../feature/feature-views/component/Features'));

const FeatureMonitoringSummary = lazy(() => import('../../feature/feature-views/component/FeatureMonitoringSummary'));
const ServiceLevelObjectives = lazy(() => import('../../feature/feature-services/component/ServiceLevelObjectives'));
const OnlineStoreMonitoring = lazy(() => import('../../feature/feature-services/component/OnlineStoreMonitoring'));

const ValidationsContainer = lazy(() => import('./validations/ValidationsContainer'));

const Jobs = lazy(() => import('../../components/redesign/jobs/JobsContainer'));
const JobDetailsContainer = lazy(() => import('../../components/jobs/JobDetails'));

import { useIsValidationsEnabled } from '../../api/validation';
import { logEvent } from '../../utils/analytics-utils';
import { useUserSettings } from '../context/UserSettingsContext';
import WorkspacesViewSelector from './workspace/WorkspacesViewSelector';

// -------------------------------- Redesign --------------------------------
const ApplicationContainer = lazy(() => import('../../components/redesign/ApplicationContainer'));

const DataFlowContainer = lazy(() => import('./dataFlow/DataFlowContainer'));
const CommitDetails = lazy(() => import('./dataFlow/CommitDetails'));

// FCOs lists
const DataSourcesContainer = lazy(() => import('./sources/DataSourcesContainer'));
const DataSetsContainer = lazy(() => import('./dataSets/DatasetsListContainer'));
const TransformationsContainer = lazy(() => import('./transformations/TransformationsContainer'));
const EntitiesContainer = lazy(() => import('./entities/EntitiesContainer'));
const FeatureViewsContainer = lazy(() => import('./featureViews/FeatureViewsContainer'));
const FeatureServicesContainer = lazy(() => import('../featureServices/Redesign/FeatureServicesContainer'));

// FCOs detail pages
const SourceContainerContainer = lazy(() => import('./sources/SourceContainer'));
const EntityContainerContainer = lazy(() => import('./entities/EntityContainer'));
const TransformationContainerContainer = lazy(() => import('./transformations/TransformationContainer'));
const FeatureViewContainer = lazy(() => import('./featureViews/FeatureViewContainer'));
const FeatureServiceContainer = lazy(() => import('./featureServices/FeatureServiceContainer'));
const DatasetContainerContainer = lazy(() => import('./dataSets/DatasetContainer'));

// ACL Summary
const AccountsAndAccessOutlet = lazy(() => import('./acl/AccountsAndAccessOutlet'));
const ACLUserListContainer = lazy(() => import('./acl/ACLUserListContainer'));
const ACLServiceAccountListsContainer = lazy(() => import('./acl/ACLServiceAccountListsContainer'));
const ACLGroupListContainer = lazy(() => import('./acl/ACLGroupsContainer'));

// ACL Details
const ACLUserProfileContainer = lazy(() => import('./acl/ACLUserProfileContainer'));
const ACLServiceAccountContainer = lazy(() => import('./acl/ACLServiceAccountContainer'));
const ACLGroupDetailsContainer = lazy(() => import('./acl/ACLGroupDetailsContainer'));

// Permissions
const PermissionsOutlet = lazy(() => import('./permissions/PermissionsOutlet'));
const PermissionsUserListContainer = lazy(() => import('./permissions/PermissionsUserListContainer'));
const PermissionsServiceAccountsListContainer = lazy(
  () => import('./permissions/PermissionsServiceAccountsListContainer')
);
const PermissionsGroupsListContainer = lazy(() => import('./permissions/PermissionsGroupsListContainer'));

// Misc
const MonitoringContainer = lazy(() => import('./monitoring/MonitoringContainer'));

// Debug
const Debug = lazy(() => import('./debug/Debug'));
const FeatureViewDebug = lazy(() => import('./debug/FeatureViewDebug'));
const FeatureServiceDebug = lazy(() => import('./debug/FeatureServiceDebug'));

const routesArray = Object.values(AppRoutes);
const routeMatch = (path: string): string | undefined => {
  return routesArray.find((route: any) => matchPath(route, path));
};

const ApplicationRoutes = () => {
  const { isGuidedOnboardingEnabled } = useGlobalState();
  const isValidationsEnabled = useIsValidationsEnabled();
  const { canShowMainApplication, workspace } = useUserSettings();

  const previousPath = useRef<string | undefined>(undefined);

  const { pathname } = useLocation();

  useEffect(() => {
    // ONLY LOG WHEN BASE PATH CHANGES
    // WE CAN HANDLE CHANGING PARAMS SEPARATELY
    if (pathname && pathname !== previousPath.current) {
      const rt = routeMatch(pathname);
      logEvent('Viewed UI Route', undefined, { path: pathname, route: rt });
      previousPath.current = pathname;
    }
  }, [pathname]);

  // APP_CUES INIT FOR Community Cluster
  useAppCuesInitializer();

  return (
    <Routes>
      <Route path={AppRoutes.root} element={<ApplicationContainer />}>
        {canShowMainApplication ? (
          <>
            {/* Workspace Routes */}
            <Route path="/repo" element={<Workspace />}>
              <>
                <Route path={AppRoutes.dataflow} element={<DataFlowContainer />} />
                <Route path={AppRoutes.commitDetails} element={<CommitDetails />} />
              </>

              <Route path={AppRoutes.features} element={<Features />} />

              {/* FCOs lists */}
              <Route path={AppRoutes.dataSources} element={<DataSourcesContainer />} />
              <Route path={AppRoutes.transformations} element={<TransformationsContainer />} />
              <Route path={AppRoutes.entities} element={<EntitiesContainer />} />
              <Route path={AppRoutes.featureViews} element={<FeatureViewsContainer />} />
              <Route path={AppRoutes.featureServices} element={<FeatureServicesContainer />} />
              <Route path={AppRoutes.savedFeatureDataFrames} element={<DataSetsContainer />} />

              {/* FCO detail pages */}
              <Route path={AppRoutes.dataSource} element={<SourceContainerContainer />} />
              <Route path={AppRoutes.entity} element={<EntityContainerContainer />} />
              <Route path={AppRoutes.transformation} element={<TransformationContainerContainer />} />
              <Route path={AppRoutes.featureView} element={<FeatureViewContainer />} />
              <Route path={AppRoutes.featureService} element={<FeatureServiceContainer />} />
              <Route path={AppRoutes.savedFeatureDataFrame} element={<DatasetContainerContainer />} />

              {/* Monitoring */}
              <Route path={AppRoutes.monitoring} element={<MonitoringContainer />} />

              {/* Not yet migrated */}
              <Route path={AppRoutes.featureMonitoringSummary} element={<FeatureMonitoringSummary />} />
              <Route path={AppRoutes.featuresByEntity} element={<Features />} />
              <Route path={AppRoutes.sloDashboard} element={<ServiceLevelObjectives />} />
              <Route path={AppRoutes.featureServerMonitoring} element={<OnlineStoreMonitoring />} />

              {/* Permissions */}
              <Route path={AppRoutes.configurations} element={<PermissionsOutlet />}>
                <Route index element={<Navigate to={AppRoutes.accessUsers} replace />} />
                <Route path={AppRoutes.accessUsers} element={<PermissionsUserListContainer />} />
                <Route path={AppRoutes.accessServiceAccounts} element={<PermissionsServiceAccountsListContainer />} />
                <Route path={AppRoutes.accessGroups} element={<PermissionsGroupsListContainer />} />
              </Route>

              {isValidationsEnabled && <Route path={AppRoutes.validations} element={<ValidationsContainer />} />}
            </Route>
            <Route path={AppRoutes.featureAnalytics} element={<FeatureAnalytics />} />
          </>
        ) : (
          <>
            <Route path="/repo/*" element={<WorkspacesViewSelector />} />
            <Route path="/select-a-workspace" element={<Navigate to={'/repo'} />} />
          </>
        )}

        <Route path={AppRoutes.jobs} element={<Jobs />} />
        <Route path={AppRoutes.jobDetails} element={<JobDetailsContainer />} />

        {/* compute */}
        <Route path={AppRoutes.dataPlatformConfiguration} element={<DataPlatformConfigEdit />} />

        {/* Guided onBoarding */}
        {isGuidedOnboardingEnabled && (
          <Route path={AppRoutes.onBoarding} element={<OnboardingRootRedirectPage />}>
            <Route path={AppRoutes.onBoardingChoosePlatform} element={<ChoosePlatform />} />
            <Route path={AppRoutes.onBoardingSetupInProgress} element={<SetupInProgress />} />
            <Route path={AppRoutes.onBoardingConfigureDatabricks} element={<ConfigureDatabricksPage />} />
          </Route>
        )}

        {/* ACL Routes */}
        <Route path={AppRoutes.settings} element={<AccountsAndAccessOutlet />}>
          {/* ACL Summary (Table lists)  */}
          <Route
            path={AppRoutes.accountAndAccess}
            element={<Navigate to={AppRoutes.accountsAndAccessUsers} replace />}
          />
          <Route index element={<Navigate to={AppRoutes.accountsAndAccessUsers} replace />} />
          <Route path={AppRoutes.accountsAndAccessUsers} element={<ACLUserListContainer />} />
          <Route path={AppRoutes.accountsAndAccessServiceAccounts} element={<ACLServiceAccountListsContainer />} />
          <Route path={AppRoutes.accountsAndAccessGroups} element={<ACLGroupListContainer />} />

          {/* ACL Details (Profile) pages */}
          <Route path={AppRoutes.accountsAndAccessUserProfile} element={<ACLUserProfileContainer />} />
          <Route path={AppRoutes.accountsAndAccessServiceAccountProfile} element={<ACLServiceAccountContainer />} />
          <Route path={AppRoutes.accountsAndAccessGroupsProfile} element={<ACLGroupDetailsContainer />} />
        </Route>

        <Route path={AppRoutes.selectWorkspace} element={<WorkspaceSelectionWorkflow />} />
        {/* ACL Details (Profile) pages */}
        <Route path={AppRoutes.debug} element={<Debug />} />
        <Route path={AppRoutes.featureViewDebug} element={<FeatureViewDebug />} />
        <Route path={AppRoutes.featureServiceDebug} element={<FeatureServiceDebug />} />

        {/* Catch all Routes */}
        <Route path="*" element={<PageNotFound workspace={workspace ?? 'prod'} />} />
      </Route>
    </Routes>
  );
};

export default ApplicationRoutes;
