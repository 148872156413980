import styled from '@emotion/styled';
import React, { FC, useEffect, useRef } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { gridRow } from './GridRow';
import { useWindowSize } from './useWindowSize';

interface ServingStatusHeaderRowProps {
  setMaxWidth: React.Dispatch<React.SetStateAction<number>>;
}

const ServingStatusHeaderRow: FC<ServingStatusHeaderRowProps> = ({ setMaxWidth }) => {
  const { theme } = useTectonTheme();

  const timelineHeaderCellRef = useRef<HTMLDivElement | null>(null);
  const windowSize = useWindowSize();

  const GridRow = gridRow(theme);

  const ChartTableHeader = styled.div`
    background-color: ${theme.colors.lightestShade};
    display: flex;
    padding: ${theme.padding.s} ${theme.padding.l};
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    font-size: ${theme.font.fontSizes.xs};
    border-bottom: ${theme.border.thin};

    :first-of-type {
      border-top-left-radius: ${theme.border.radius.medium};
      border-right: ${theme.border.thin};
    }

    :last-of-type {
      border-top-right-radius: ${theme.border.radius.medium};
      border-left: ${theme.border.thin};
    }
  `;

  useEffect(() => {
    if (timelineHeaderCellRef.current) {
      setMaxWidth(timelineHeaderCellRef.current.getBoundingClientRect().width);
    }
  }, [windowSize, setMaxWidth]);

  return (
    <GridRow>
      <ChartTableHeader>Feature View</ChartTableHeader>
      <ChartTableHeader ref={timelineHeaderCellRef}>Materialization Status</ChartTableHeader>
      <ChartTableHeader>Last Processed</ChartTableHeader>
    </GridRow>
  );
};

export default ServingStatusHeaderRow;
