import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserSettings } from './context/UserSettingsContext';

declare const window: any;

export const useAppCuesInitializer = () => {
  const [hasInitializedAppCues, setHasInitializedAppCues] = useState(false);
  const [hasSetAppCuesUser, setHasSetAppCuesUser] = useState(false);
  const { isPublicFreeTrial, user, isProductFruitsEnabled } = useUserSettings();

  const { pathname } = useLocation();
  const previousPath = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (isPublicFreeTrial && !isProductFruitsEnabled && !hasInitializedAppCues) {
      const head = document.querySelector('head');
      const script = document.createElement('script');

      script.setAttribute('src', '//fast.appcues.com/202552.js');
      script.onload = () => {
        setHasInitializedAppCues(true);
      };

      head?.appendChild(script);

      return () => {
        head?.removeChild(script);
      };
    }

    if (window.Appcues && user?.email && !hasSetAppCuesUser) {
      window.Appcues.identify(
        user?.email, // unique, required
        {
          // recommended (optional) properties
          // createdAt: 1566932390, // Unix timestamp of user signup date
          // purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
          // planTier: 'Standard', // Current user’s plan tier
          // role: 'Admin', // Current user’s role or permissions
          // accountId: '1234', // Current user's account ID
          // firstName: 'John', // current user's first name
          // // additional suggestions
          // companyName: 'Acme Corp', // Current user’s company name
          email: user?.email, // Current user's email
          // location: '90210', // a zipcode, state, or country enables location-based targeting
          // version: '2.0', // users on different versions may need to see different content
          // language: 'spanish', // for multi-language applications
          // renewalDate: 1577880288, // to remind users to renew
        }
      );
      setHasSetAppCuesUser(true);
    }
  }, [isPublicFreeTrial, user, user?.email, hasInitializedAppCues, hasSetAppCuesUser]);

  useEffect(() => {
    // ONLY call WHEN BASE PATH CHANGES
    if (window.Appcues && pathname && pathname !== previousPath.current) {
      window.Appcues.page();
    }
  }, [pathname]);
};

export default useAppCuesInitializer;
