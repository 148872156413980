import { Global, css } from '@emotion/react';

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        * {
          line-height: 1.5;
        }

        body {
          font-family: Inter;
          font-size: 14px;
        }

        a {
          color: black;

          :hover {
            text-decoration: underline;
          }
        }

        li {
          line-height: 20px;
        }

        .euiFormControlLayoutCustomIcon svg,
        svg,
        svg.euiIcon {
          fill: none;
        }

        .strokeCurrentColor svg {
          stroke: currentColor;
        }

        .no-stroke svg,
        svg.no-stroke,
        svg.no-stroke.euiIcon,
        svg.euiIcon .no-stroke {
          stroke: none !important;
          fill: currentColor;
        }
      `}
    />
  );
};

export default GlobalStyles;
