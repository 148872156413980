import React, { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';

import { getSideNavigationWorkspaces } from '../util';
import { useUserSettings } from '../../context/UserSettingsContext';

import {
  EmptyPrompt,
  EuiBasicTableColumn,
  FlexGrid,
  FlexGroupWrapper,
  FlexItem,
  Flyout,
  Icon,
  SearchAndActionButton,
  Table,
  Workspace,
} from '@tecton/ComponentRedesign';

import { useGetACLUserDetails } from '../../../api/queries/aclGraphql';

// icons
import LiveWorkspaceColor from '@svg/live-workspace-color.svg';
import LiveWorkspace from '@svg/live-workspace.svg';
import DevelopmentWorkspaceColor from '@svg/development-workspace-color.svg';
import DevelopmentWorkspace from '@svg/development-workspace.svg';
import Recent from '@svg/recent.svg';
import Plus from '@svg/plus.svg';
import Mail from '@svg/mail.svg';
import HowToCreateWorkspace from './HowToCreateWorkspace';

const WorkspaceNameStyle = styled.div`
  width: 100%;
  cursor: pointer;
`;

const RecentTitle = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.headingSizes['4']};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.headingLineHeights['3']};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.font.headingSizes['4']};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.headingLineHeights['3']};
`;

const TitleWithOutAccess = styled.div`
  color: ${({ theme }) => theme.colors.darkShade};
  font-size: ${({ theme }) => theme.font.headingSizes['4']};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.headingLineHeights['3']};
`;

const LoadingContainer = styled.div`
  align-self: center;
`;

const RoleTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
`;

const RequestAccessTitle = styled.div`
  color: ${({ theme }) => theme.colors.title};
`;

interface WorkspaceRole {
  workspace: string;
  role: string;
}

const WorkspaceLayout: FC<{ header: ReactNode; body: ReactNode }> = ({ header, body }) => {
  return (
    <FlexItem>
      <FlexGrid columns={1} gutterSize="s">
        <FlexItem>{header}</FlexItem>
        <FlexItem>{body}</FlexItem>
      </FlexGrid>
    </FlexItem>
  );
};

const getWorkspaceRole = (workspace: string, workspaceRoles: WorkspaceRole[]) => {
  return workspaceRoles.find((workspaceRole) => {
    return workspaceRole.workspace === workspace;
  });
};

const WorkspaceSelector: FC = () => {
  const navigate = useNavigate();
  const { user, isAdmin, updateWorkspace, allWorkspaces } = useUserSettings();

  // general states
  const [search, setSearch] = useState<string>();
  const [IsFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);

  const { data, isLoading } = useGetACLUserDetails({
    email: user?.email ?? '',
    isAdmin: isAdmin ?? false,
  });

  const workspaces = getSideNavigationWorkspaces(allWorkspaces ?? []);

  const userWorkspaceRoles =
    data?.GetUserDetails?.userDetails?.assignedWorkspacesWithRoles?.map((userRole): WorkspaceRole => {
      return { workspace: userRole.workspaceId, role: userRole.role?.effectiveWorkspaceRole ?? '' };
    }) ?? [];

  const onWorkspaceClick = (workspace: any) => {
    updateWorkspace(workspace.name);
    navigate('/repo/' + workspace.name + '/home/dataflow');
  };

  const filterWorkspace = (ws: Workspace) => {
    if (!search) {
      return true;
    }
    return ws.name.toLowerCase().includes(search.toLowerCase());
  };

  const columns: EuiBasicTableColumn<Workspace>[] = [
    {
      name: 'Workspace Name',
      width: '40%',
      render: (workspace: Workspace) => {
        const workspaceRole = getWorkspaceRole(workspace.name, userWorkspaceRoles);
        const hasWorkspaceAccess = !(workspaceRole === undefined);

        return hasWorkspaceAccess ? (
          <WorkspaceNameStyle
            onClick={() => {
              onWorkspaceClick(workspace);
            }}
          >
            <RecentTitle>
              {workspace?.workspaceType === 'Live' ? (
                <div>
                  <Icon type={LiveWorkspaceColor} /> {workspace.name}
                </div>
              ) : (
                <div>
                  <Icon type={DevelopmentWorkspaceColor} /> {workspace.name}
                </div>
              )}
            </RecentTitle>
          </WorkspaceNameStyle>
        ) : (
          <div>
            <TitleWithOutAccess>
              {workspace?.workspaceType === 'Live' ? (
                <div>
                  <Icon type={LiveWorkspaceColor} /> {workspace.name}
                </div>
              ) : (
                <div>
                  <Icon type={DevelopmentWorkspaceColor} /> {workspace.name}
                </div>
              )}
            </TitleWithOutAccess>
          </div>
        );
      },
    },
    {
      name: 'Role',
      render: (workspace: Workspace) => {
        const workspaceRole = getWorkspaceRole(workspace.name, userWorkspaceRoles);
        const hasWorkspaceAccess = !(workspaceRole === undefined);
        return hasWorkspaceAccess ? (
          <WorkspaceNameStyle
            onClick={() => {
              onWorkspaceClick(workspace);
            }}
          >
            <RoleTitle>{workspaceRole.role}</RoleTitle>
          </WorkspaceNameStyle>
        ) : (
          <RequestAccessTitle>
            <Icon type={Mail} /> Request Access
          </RequestAccessTitle>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <LoadingContainer>
        <EmptyPrompt title={<>Loading Workspaces</>} body={<></>} variant="loading" orientation="vertical" />
      </LoadingContainer>
    );
  }

  return (
    <>
      {IsFlyoutOpen && (
        <>
          <Flyout
            title={<>How to create a workspace</>}
            onClose={() => {
              setIsFlyoutOpen(false);
            }}
          >
            <HowToCreateWorkspace />
          </Flyout>
        </>
      )}

      <FlexGroupWrapper direction="column" gap="l">
        <FlexItem>
          <SearchAndActionButton
            searchPlaceHolder="Search Workspaces"
            actionButtonLabel={'Create Workspace'}
            actionButtonVariant="emptyAction"
            onActionButtonClick={() => {
              setIsFlyoutOpen(true);
            }}
            onSearchInputChange={(value) => {
              setSearch(value);
            }}
            leftButtonIcon={Plus}
          />
          {workspaces.recent.length > 0 && (
            <>
              <FlexGrid columns={1} gutterSize="s">
                <FlexItem>
                  <RecentTitle>
                    <Icon type={Recent} /> Recently Accessed
                  </RecentTitle>
                </FlexItem>
                <FlexItem>
                  <div style={{ maxHeight: '30vh', overflow: 'scroll' }}>
                    <Table items={workspaces.recent.filter(filterWorkspace) ?? []} columns={columns} layout="fixed" />
                  </div>
                </FlexItem>
              </FlexGrid>
            </>
          )}
        </FlexItem>
        {workspaces.live.length > 0 && (
          <WorkspaceLayout
            header={
              <Title>
                <Icon type={LiveWorkspace} /> Live
              </Title>
            }
            body={
              <div style={{ maxHeight: '30vh', overflow: 'scroll' }}>
                <Table items={workspaces.live.filter(filterWorkspace) ?? []} columns={columns} layout="fixed" />
              </div>
            }
          />
        )}

        {workspaces.development.length > 0 && (
          <WorkspaceLayout
            header={
              <Title>
                <Icon type={DevelopmentWorkspace} /> Development
              </Title>
            }
            body={
              <div style={{ maxHeight: '30vh', overflow: 'scroll' }}>
                <Table items={workspaces.development.filter(filterWorkspace) ?? []} columns={columns} layout="fixed" />
              </div>
            }
          />
        )}
      </FlexGroupWrapper>
    </>
  );
};

export default WorkspaceSelector;
