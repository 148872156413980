import React, { Suspense } from 'react';
import styled from '@emotion/styled';

import FeatureFlagModal from './FeatureFlagModal';
import ApplicationRoute from './ApplicationRoute';
import InitializeApplication from '../../core/initialization/InitializeApplication';

import { ToastContextProvider } from '@tecton/ToastContext';
import { TectonThemeProvider } from '@tecton/ComponentRedesign/Theme';
import { GlobalToast } from '@tecton';
import { ErrorBoundary } from '@tecton/ComponentRedesign';

const Container = styled.div`
  background: #f7f8fc;
  display: flex;
  height: 100%;
  width: 100%;
  scrollbar-color: rgba(105, 112, 125, 0.5) #f0f1f4;
  font-family: 'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  text-size-adjust: 100%;
  font-kerning: normal;
  color: #343741;
`;

const WebUI = () => {
  return (
    <Container>
      <TectonThemeProvider>
        <ErrorBoundary>
          <ToastContextProvider>
            <GlobalToast />
            <InitializeApplication>
              <Suspense>
                <ApplicationRoute />
              </Suspense>
              <FeatureFlagModal />
            </InitializeApplication>
          </ToastContextProvider>
        </ErrorBoundary>
      </TectonThemeProvider>
    </Container>
  );
};

export default WebUI;
