import WorkspaceUtils from '../../utils/workspace-utils';
import { AvailableWorkspaces } from '../@tecton/ComponentRedesign/SharedComponentTypes';
import { WorkspaceWithPermissions } from '../context/types';

export const getSideNavigationWorkspaces = (workspaces: WorkspaceWithPermissions[]): AvailableWorkspaces => {
  const recentWorkspaces: string[] = WorkspaceUtils.getRecentWorkspaces();
  return workspaces?.reduce(
    (availableWorkspaces: AvailableWorkspaces, workspace) => {
      workspace?.capabilities?.materializable
        ? availableWorkspaces?.live.push({
            name: workspace.name ?? '',
            workspaceType: 'Live',
            isAccessible: workspace.isAccessible,
          })
        : availableWorkspaces?.development.push({
            name: workspace.name ?? '',
            workspaceType: 'Development',
            isAccessible: workspace.isAccessible,
          });

      if (recentWorkspaces.includes(workspace?.name ?? '')) {
        availableWorkspaces?.recent.push({
          name: workspace.name ?? '',
          workspaceType: workspace?.capabilities?.materializable ? 'Live' : 'Development',
          isAccessible: workspace.isAccessible,
        });
      }

      return availableWorkspaces;
    },
    {
      recent: [],
      live: [],
      development: [],
    }
  );
};

export const getSideNavigationInformation = (
  route: string,
  currentWorkspace: string,
  matchPath: any //it's hook so I have to pass it
): { to: string; isActive: boolean } => {
  const to = route.replace(`:workspace`, currentWorkspace).replace('/*', '');

  const match = matchPath({
    path: route,
    end: false,
  });
  const isActive = !!match;

  return { to, isActive };
};
