import { EuiBadge, EuiFlexItem, tint } from '@elastic/eui';
import React, { FC } from 'react';
import { FlexGroup, useTectonTheme } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

export type BadgeVariants =
  | 'default'
  | 'hollow'
  | 'custom'
  | 'primary'
  | 'accent'
  | 'danger'
  | 'warning'
  | 'disabled'
  | 'success';

interface TectonBadgeProps {
  label: string;
  value?: string;
  icon?: React.ReactNode;
  variant?: BadgeVariants;
}

export type ColorPairProps = Record<BadgeVariants, { foreground: string; background: string }>;

const StyledBadge = styled(EuiBadge)<{
  background: string;
  foreground: string;
  theme: Theme;
  border: boolean;
}>`
  color: ${(props) =>
    props.foreground} !important; // Have to add !important here because otherwise EUI overrides the CSS with an HTML attribute
  background-color: ${(props) =>
    props.background} !important; // Have to add !important here because otherwise EUI overrides the CSS with an HTML attribute
  padding: ${({ theme }) => `${theme.padding.xxs} ${theme.padding.s}`};
  border-radius: ${({ theme }) => theme.border.radius.large};
  border: ${(props) =>
    props.border === true
      ? props.theme.border.thin
      : 'none'}; // Passing in the theme here to ensure that I have access to both props and theme for hollow borders
`;

const BadgeIcon = styled(EuiFlexItem)`
  padding-right: ${({ theme }) => theme.padding.xs};
`;

const KeyString = styled(EuiFlexItem)`
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const ValueString = styled(EuiFlexItem)`
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  padding-left: ${({ theme }) => theme.padding.xs};
`;

export const TectonBadge: FC<TectonBadgeProps> = ({ variant, label, value, icon }) => {
  const { theme } = useTectonTheme();

  const foreground = undefined;
  const background = undefined;

  const colorPairs: ColorPairProps = {
    default: { foreground: theme.colors.text, background: theme.colors.lightestShade },
    hollow: { foreground: theme.colors.text, background: theme.colors.emptyShade },
    custom: {
      foreground: foreground || theme.colors.text,
      background: background || theme.colors.lightestShade,
    },
    primary: {
      foreground: theme.categoryColorPairs[1].text, //Viz/Behind Text/_euiColorVis1_behindText
      background: theme.categoryColorPairs[1].behindText,
    },
    accent: {
      foreground: theme.categoryColorPairs[3].text,
      background: theme.categoryColorPairs[3].behindText,
    },
    success: {
      foreground: theme.categoryColorPairs[0].text,
      background: theme.categoryColorPairs[0].behindText,
    },
    danger: {
      foreground: theme.categoryColorPairs[9].text,
      background: theme.categoryColorPairs[9].behindText,
    },
    warning: {
      foreground: theme.categoryColorPairs[5].text,
      background: theme.categoryColorPairs[5].behindText,
    },
    disabled: {
      foreground: theme.colors.disabledText,
      background: tint(theme.colors.disabled, 0.9),
    },
  };

  const colorPair = colorPairs[variant ?? 'default'];

  return (
    <StyledBadge
      background={colorPair.background}
      foreground={colorPair.foreground}
      data-test-id={`badge-${label}-${value}`}
      border={variant === 'hollow'}
      theme={theme}
    >
      <FlexGroup gutterSize="none" responsive={false} justifyContent="flexEnd">
        {icon && <BadgeIcon grow={false}>{icon}</BadgeIcon>}
        <KeyString grow={false}>
          {label}
          {value && <>:</>}
        </KeyString>
        {value && <ValueString grow={false}>{value}</ValueString>}
      </FlexGroup>
    </StyledBadge>
  );
};

export default TectonBadge;
