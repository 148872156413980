import styled from '@emotion/styled';
import React, { FC } from 'react';

interface ViewLayoutProps {
  header: React.ReactNode;
  body: React.ReactNode;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: 0px ${({ theme }) => theme.padding.xl} ${({ theme }) => theme.padding.xl} ${({ theme }) => theme.padding.xl};
  overflow: scroll;
  border-radius: 0px 0px ${({ theme }) => theme.padding.xl} ${({ theme }) => theme.padding.xl};
`;

const ViewLayout: FC<ViewLayoutProps> = ({ header, body }) => {
  return (
    <Wrapper data-testid="view-layout">
      {header}
      <Body>{body}</Body>
    </Wrapper>
  );
};

export default ViewLayout;
