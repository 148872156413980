import { Metadata_Service } from '.';
import { useQuery } from '@tanstack/react-query';
import { GetFcosRequest, GetFcosResponse } from '../types/tecton_proto/metadataservice/metadata_service';
import { Fco } from '../types/tecton_proto/data/fco';
import {
  DataSourceFCO,
  DataSourceFCOFields,
  EntityFCO,
  EntityFCOFields,
  FCO,
  FeatureServiceFCO,
  FeatureViewFCO,
  TransformationFCO,
  TransformationFCOFields,
  WorkspaceFCOContainer,
} from '../core/types/fcoTypes';
import { transformFeatureViewProtoToFeatureViewFCO } from './fcoUtils/featureViewFcoUtils';
import { transformDataSourceProtoToDataSourceFCO } from './fcoUtils/dataSourceFcoUtils';
import { transformEntityProtoToEntitiyFCO } from './fcoUtils/entityFCOUtils';
import { transformTransformationProtoToTransformationFCO } from './fcoUtils/transformationFCOUtils';
import { transformFeatureServiceProtoToFeatureServiceFCO } from './fcoUtils/featureServiceFcoUtils';

const transformTransformations: (response: GetFcosResponse) => Record<string, TransformationFCO> = (response) => {
  const transformations: Record<string, TransformationFCO> = {};

  response.fco_container?.fcos
    ?.filter((fco: Fco) => {
      return fco.transformation !== undefined;
    })
    .forEach((fco: Fco) => {
      const transformedTransformation = transformTransformationProtoToTransformationFCO(fco);
      transformations[transformedTransformation.id] = transformedTransformation;
    });

  return transformations;
};

const transformDataSources: (response: GetFcosResponse) => Record<string, DataSourceFCO> = (response) => {
  const dataSources: Record<string, DataSourceFCO> = {};

  response.fco_container?.fcos
    ?.filter((fco: Fco) => {
      return fco.virtual_data_source !== undefined;
    })
    .forEach((fco: Fco) => {
      const transformedDataSource = transformDataSourceProtoToDataSourceFCO(fco);
      dataSources[transformedDataSource.id] = transformedDataSource;
    });

  return dataSources;
};

const transformEntities: (response: GetFcosResponse) => Record<string, EntityFCO> = (response) => {
  const entities: Record<string, EntityFCO> = {};
  response.fco_container?.fcos
    ?.filter((fco: Fco) => {
      return fco.entity !== undefined;
    })
    .forEach((fco: Fco) => {
      const transformedEntity = transformEntityProtoToEntitiyFCO(fco);
      entities[transformedEntity.id] = transformedEntity;
    });

  return entities;
};

const transformFeatureServices: (response: GetFcosResponse) => Record<string, FeatureServiceFCO> = (response) => {
  const featureServices: Record<string, FeatureServiceFCO> = {};

  response.fco_container?.fcos
    ?.filter((fco: Fco) => {
      return fco.feature_service !== undefined;
    })
    .forEach((fco: Fco) => {
      const transformedFeatureService = transformFeatureServiceProtoToFeatureServiceFCO(fco);
      featureServices[transformedFeatureService.id] = transformedFeatureService;
    });

  return featureServices;
};

const transformFeatureViews: (response: GetFcosResponse) => Record<string, FeatureViewFCO> = (response) => {
  const featureViews: Record<string, FeatureViewFCO> = {};
  response.fco_container?.fcos
    ?.filter((fco: Fco) => {
      return fco.feature_view !== undefined;
    })
    .forEach((fco: Fco) => {
      const transformedFeatureView = transformFeatureViewProtoToFeatureViewFCO(fco);
      featureViews[transformedFeatureView.id] = transformedFeatureView;
    });

  return featureViews;
};

const fetchFCOs = async (args: GetFcosRequest): Promise<WorkspaceFCOContainer> => {
  const response = await Metadata_Service('get-fcos', { data: args, method: 'POST' });

  // ID to FCO Records to allow fo efficiently handle joins further down
  const dataSources = transformDataSources(response.data);
  const entities = transformEntities(response.data);
  const featureViews = transformFeatureViews(response.data);
  const transformations = transformTransformations(response.data);
  const featureServices = transformFeatureServices(response.data);
  const idToFcoMap: Record<string, FCO> = {};

  // Join Feature Services & Feature Views
  Object.entries(featureServices).forEach(([_, featureService]) => {
    featureService.allFeatureViews.forEach((featureViewId) => {
      featureViews[featureViewId].featureServices.push(featureService.id);
    });
  });

  // Join Data Sources with Dependent FVS <- This should really be happening at the API layer
  Object.entries(featureViews).forEach(([_, featureView]) => {
    featureView.dataSourceIds.forEach((dataSourceId) => {
      dataSources[dataSourceId][DataSourceFCOFields.DEPENDENT_FEATURE_VIEWS].push(featureView.id);
    });

    // Dependent Feature Views for Entity IDS
    featureView.entityIds.forEach((entityId) => {
      entities[entityId][EntityFCOFields.DEPENDENT_FEATURE_VIEWS].push(featureView.id);
    });

    featureView.allTransformations.forEach((transformationId) => {
      transformations[transformationId][TransformationFCOFields.DEPENDENT_FEATURE_VIEWS].push(featureView.id);
    });
  });

  Object.entries(entities).forEach(([_, entity]) => {
    let allFeatureServices: string[] = [];
    entity.dependentFeatureViews.forEach((dependentId) => {
      featureViews[dependentId].featureServices.forEach((fsId) => {
        entity.dependentFeatureServices.push(fsId);
      });
      allFeatureServices = allFeatureServices.concat(featureViews[dependentId].featureServices);
    });

    entity.dependentFeatureServices = Array.from(new Set(entity.dependentFeatureServices));
  });

  Object.entries(dataSources).forEach(([_, dataSource]) => {
    let allFeatureServices: string[] = [];
    dataSource.dependentFeatureViews.forEach((dependentId) => {
      featureViews[dependentId].featureServices.forEach((fsId) => {
        dataSource.dependentFeatureServices.push(fsId);
      });
      allFeatureServices = allFeatureServices.concat(featureViews[dependentId].featureServices);
    });

    dataSource.dependentFeatureServices = Array.from(new Set(dataSource.dependentFeatureServices));
  });

  Object.entries(transformations).forEach(([_, transformation]) => {
    let allFeatureServices: string[] = [];
    transformation.dependentFeatureViews.forEach((dependentId) => {
      featureViews[dependentId].featureServices.forEach((fsId) => {
        transformation.dependentFeatureServices.push(fsId);
      });
      allFeatureServices = allFeatureServices.concat(featureViews[dependentId].featureServices);
    });

    transformation[TransformationFCOFields.DEPENDENT_FEATURE_SERVICES] = Array.from(
      new Set(transformation.dependentFeatureServices)
    );
  });

  [
    Object.entries(dataSources),
    Object.entries(entities),
    Object.entries(featureViews),
    Object.entries(transformations),
    Object.entries(featureServices),
  ]
    .flat()
    .forEach(([id, fco]) => {
      idToFcoMap[id] = fco;
    });

  // Need Name => id maps for each FCO type
  const dataSourceNameMap = mapFcoNamesToIds(dataSources);
  const entitiesNameMap = mapFcoNamesToIds(entities);
  const featureViewsNameMap = mapFcoNamesToIds(featureViews);
  const transformationsNameMap = mapFcoNamesToIds(transformations);
  const featureServicesNameMap = mapFcoNamesToIds(featureServices);

  const responseAsWorkspaceFCOs: WorkspaceFCOContainer = {
    idToFcoMap: idToFcoMap,
    dataSources: Object.entries(dataSources).map(([_, fco]) => fco),
    entities: Object.entries(entities).map(([_, fco]) => fco),
    featureServices: Object.entries(featureServices).map(([_, fco]) => fco),
    featureViews: Object.entries(featureViews).map(([_, fco]) => fco),
    dataSourcesNamesMap: dataSourceNameMap,
    entitiesNamesMap: entitiesNameMap,
    featureServicesNamesMap: featureServicesNameMap,
    featureViewsNamesMap: featureViewsNameMap,
    transformations: Object.entries(transformations).map(([_, fco]) => fco),
    transformationsNamesMap: transformationsNameMap,
  };

  response.data = responseAsWorkspaceFCOs;
  return response.data;
};

const mapFcoNamesToIds: (fcos: Record<string, FCO>) => Record<string, string> = () => {
  const nameToIdMap: Record<string, string> = {};

  return nameToIdMap;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface UseGetFcosOptions<T = any> {
  // TODO: Define return type
  select?: (data: WorkspaceFCOContainer) => T;
  enabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useGetFcos = <T = WorkspaceFCOContainer>(workspace: string, options: UseGetFcosOptions) => {
  return useQuery(
    ['get-fcos-for-workspace-interfaces', workspace],
    () => {
      return fetchFCOs({ workspace });
    },
    options
  );
};

export const useGetFeatureViewByName = (featureViewName: string, workspace: string) => {
  const { data } = useGetFcos(workspace, {
    select: (data: WorkspaceFCOContainer) => {
      return data?.featureViews.find((featureView) => {
        return featureView.name === featureViewName;
      });
    },
  });

  return data;
};

export const useGetFeatureServiceByName = (featureServicesName: string, workspace: string) => {
  const { data } = useGetFcos(workspace, {
    select: (data: WorkspaceFCOContainer) => {
      return data?.featureServices.find((featureServices) => {
        return featureServices.name === featureServicesName;
      });
    },
    enabled: !!workspace,
  });
  return data;
};

export const useGetDataSourceByName = (dataSourceName: string, workspace: string) => {
  const { data } = useGetFcos(workspace, {
    select: (data: WorkspaceFCOContainer) => {
      return data?.dataSources.find((dataSource) => {
        return dataSource.name === dataSourceName;
      });
    },
    enabled: !!workspace,
  });
  return data;
};

export const useGetEntityByName = (entityName: string, workspace: string) => {
  const { data } = useGetFcos(workspace, {
    select: (data: WorkspaceFCOContainer) => {
      return data?.entities.find((entity) => {
        return entity.name === entityName;
      });
    },
    enabled: !!workspace,
  });
  return data;
};

export const useGetTransformationByName = (transformationName: string, workspace: string) => {
  const { data } = useGetFcos(workspace, {
    select: (data: WorkspaceFCOContainer) => {
      return data?.transformations.find((transformation) => {
        return transformation.name === transformationName;
      });
    },
    enabled: !!workspace,
  });
  return data;
};
