import React, { FC } from 'react';
import styled from '@emotion/styled';
import LinkIcon from './LinkIcon';

import { ReactComponent as Book } from '@svg/book.svg';

export interface TectonSubtitleProps {
  description?: string;
  learnMoreUrl?: string;
}

const DescriptionBar = styled.div`
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: 0;
`;

const DescriptionStyle = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const TectonSubtitle: FC<TectonSubtitleProps> = ({ description, learnMoreUrl }) => {
  return (
    <DescriptionBar data-testid="subtitle">
      <DescriptionStyle>{description}</DescriptionStyle>
      {learnMoreUrl && (
        <>
          <LinkIcon linkName={'Learn More'} href={learnMoreUrl} iconType={Book} target="_blank" />
        </>
      )}
    </DescriptionBar>
  );
};

export default TectonSubtitle;
