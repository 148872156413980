import React, { useEffect, useState } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useUserSettings } from './context/UserSettingsContext';

export const ProductFruitsInit = () => {
  const { isProductFruitsEnabled, user } = useUserSettings();
  const [userInfo, setUserInfo] = useState<any>(null);
  useEffect(() => {
    if (isProductFruitsEnabled && user) {
      const { preferred_username, email, given_name, family_name, email_verified, tecton_admin } = user as any;
      setUserInfo({
        username: preferred_username,
        email: email,
        firstname: given_name,
        lastname: family_name,
        props: { tecton_admin: tecton_admin, email_verified: email_verified },
      });
    }
  }, [isProductFruitsEnabled, user]);

  return userInfo?.email && isProductFruitsEnabled ? (
    <ProductFruits workspaceCode="61GD27oBragQlk6P" language="en" user={userInfo} />
  ) : null;
};

export default ProductFruitsInit;
