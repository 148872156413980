import React, { FC } from 'react';
import styled from '@emotion/styled';
import { getInitialsFromString, hashForAvatars } from '../utils';
import TruncateText from './TruncateText';

interface TectonAvatarGroupProps {
  names: string[];
}

export const TectonAvatarGroup: FC<TectonAvatarGroupProps> = ({ names }) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        {names.map((name, index) => {
          const AvatarWrapper = styled.div`
            position: relative;
            display: inline;
            left: ${-index * 5}px;
            z-index: -${index};
          `;
          return (
            <AvatarWrapper>
              <TectonAvatar name={name} />
            </AvatarWrapper>
          );
        })}
      </div>
    </>
  );
};

interface TectonAvatarProps {
  name?: string;
  showName?: boolean;
  size?: 's' | 'm' | 'l';
  square?: boolean;
  icon?: React.ReactNode;
  backgroundColor?: string;
  color?: string;
}

const StyledAvatar = styled.div<{
  hashIndex: number;
  square?: boolean;
  size: 's' | 'm' | 'l';
  backgroundColor?: string;
  color?: string;
}>(({ theme, hashIndex, square, size, backgroundColor, color }) => {
  const borderRadius = square ? `${theme.border.radius.medium}` : size === 's' ? `${theme.size.m}` : `${theme.size.l}`;

  return {
    display: 'flex',
    width: size === 's' ? `${theme.size.l}` : `${theme.size.xl}`,
    height: size === 's' ? `${theme.size.l}` : `${theme.size.xl}`,
    borderRadius: borderRadius,
    border: `${size === 's' ? '0px' : '1px'} solid ${theme.colors.emptyShade}`,
    backgroundColor: backgroundColor ?? theme.avatarColors[hashIndex].background,
    color: color ?? theme.avatarColors[hashIndex].foreground,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: size === 's' ? theme.font.fontSizes.xxs : theme.font.fontSizes.s,
    cursor: 'pointer',

    '& span': {
      fontSize: theme.font.fontSizes.s,
    },
  };
});

const AvatarWrapper = styled.div<{ showName: boolean }>(({ theme, showName }) => ({
  display: showName ? 'inline-grid' : 'inline-flex',
  gap: theme.padding.xs,
  alignItems: 'center',
  gridTemplateColumns: `${theme.padding.xl} 1fr`,
  width: 'auto',
}));

const NameDisplay = styled.div(({ theme }) => ({
  fontSize: theme.font.fontSizes.s,
  lineHeight: theme.font.lineHeights.s,
  display: `inline-block`,
  maxWidth: `220px`,
  whiteSpace: `nowrap`,
  wordBreak: `keep-all`,
  textOverflow: `ellipsis`,
  overflow: `hidden`,
}));

const TectonAvatar: FC<TectonAvatarProps> = ({
  name = '',
  showName,
  size = 's',
  square,
  icon,
  backgroundColor,
  color,
}) => {
  const hashIndex = hashForAvatars(name);
  const initials = getInitialsFromString(name);

  return (
    <AvatarWrapper data-testid="avatar-wrapper" showName={showName ?? false}>
      <StyledAvatar
        data-testid="avatar-initials"
        hashIndex={hashIndex}
        square={square}
        size={size}
        backgroundColor={backgroundColor}
        color={color}
      >
        {icon ? icon : initials}
      </StyledAvatar>
      {showName && (
        <NameDisplay data-testid="avatar-name">
          <TruncateText>{name}</TruncateText>
        </NameDisplay>
      )}
    </AvatarWrapper>
  );
};

export default TectonAvatar;
