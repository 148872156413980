import React, { FC } from 'react';
import {
  AnyFCO,
  DatasetFCO,
  FCOFields,
  FCOType,
  FeatureServiceFCO,
  FeatureViewFCO,
  FeatureViewFCOFields,
  FeatureViewFCOType,
} from '../../../core/types/fcoTypes';
import { TectonDateTimeFormat } from './utils';
import moment from 'moment';
import { EuiBasicTableColumn } from '@elastic/eui';
import { Link } from 'react-router-dom';
import {
  EmptyPrompt,
  Table,
  Button,
  FCOIconAndName,
  Avatar,
  EnabledDisabledBadge,
  Loading,
  LinkIcon,
} from '@tecton/ComponentRedesign';
import { ReactComponent as BookIcon } from '@svg/book.svg';

interface RelatedFeatureViewsTableProps {
  fco: AnyFCO;
  type: FCOType.FEATURE_VIEW | FCOType.FEATURE_SERVICE | FCOType.DATASET;
  workspace?: string;
  featureViews?: FeatureViewFCO[];
  featureServices?: FeatureServiceFCO[];
  datasets?: DatasetFCO[];
  idToFcoMap?: Record<string, AnyFCO>;
}

const RelatedFeatureViewsTable: FC<{ fco: AnyFCO; featureViews: FeatureViewFCO[]; workspace?: string }> = ({
  fco,
  featureViews,
  workspace,
}) => {
  const fvTypeMap: Record<FeatureViewFCOType, string> = {
    [FeatureViewFCOType.STREAM]: 'Stream',
    [FeatureViewFCOType.BATCH]: 'Batch',
    [FeatureViewFCOType.STREAM_WINDOW_AGGREGATE]: 'Stream',
    [FeatureViewFCOType.BATCH_WINDOW_AGGREGATE]: 'Batch',
    [FeatureViewFCOType.REALTIME]: 'On-Demand',
    [FeatureViewFCOType.FEATURE_TABLE]: 'Feature Table',
    [FeatureViewFCOType.UNKNOWN]: '',
    [FeatureViewFCOType.PUSH_WITH_BATCH]: 'Push',
    [FeatureViewFCOType.PUSH_NO_BATCH]: 'Push',
  };

  const dependentFeatureViewsColumns = [
    {
      name: 'Feature View',
      render: (fv: FeatureViewFCO) => (
        <Link to={`../../${workspace}/features/${fv.name}`}>
          <FCOIconAndName type={FCOType.FEATURE_VIEW} name={fv.name ?? ''} description={fv.description} />
        </Link>
      ),
    },
    {
      name: 'Type',
      render: (fv: FeatureViewFCO) => fvTypeMap[fv[FeatureViewFCOFields.FEATURE_VIEW_TYPE]],
    },
    {
      name: 'Online',
      render: (fv: FeatureViewFCO) => (
        <EnabledDisabledBadge enabled={fv[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED] ?? false} />
      ),
    },
    {
      name: 'Offline',
      render: (fv: FeatureViewFCO) => (
        <EnabledDisabledBadge enabled={fv[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED] ?? false} />
      ),
    },
    {
      name: 'Last Updated',
      render: (fv: FeatureViewFCO) => TectonDateTimeFormat(moment(fv[FCOFields.LAST_MODIFIED_DATE])),
    },
    {
      name: 'Last Updated By',
      render: (fv: FeatureViewFCO) => (
        <div style={{ width: '200px' }}>
          <Avatar name={fv[FCOFields.LAST_MODIFIED_BY] ?? ''} showName />
        </div>
      ),
    },
    {
      name: 'Owner',
      render: (fv: FeatureViewFCO) => (
        <div style={{ width: '200px' }}>
          <Avatar name={fv[FCOFields.OWNER] ?? ''} showName />
        </div>
      ),
    },
    {
      name: 'Created',
      render: (fv: FeatureViewFCO) => TectonDateTimeFormat(moment(fv[FCOFields.CREATED_DATE])),
    },
  ];

  return (
    <Table
      compressed
      items={featureViews ?? []}
      columns={dependentFeatureViewsColumns}
      emptyPrompt={
        <EmptyPrompt
          orientation="horizontal"
          title={<>No Related Feature Views</>}
          body={
            <>
              <p>
                <FCOIconAndName name={fco.name ?? ''} type={fco[FCOFields.FCO_TYPE]} /> is not utilized by any existing
                Feature Views. This means that it is not currently used in any feature serving pipeline.
              </p>
            </>
          }
          actions={<Button variant="primaryAction" label={'Learn More'} />}
        />
      }
    />
  );
};

const RelatedFeatureServicesTable: FC<{ fco: AnyFCO; featureServices: FeatureServiceFCO[]; workspace?: string }> = ({
  fco,
  featureServices,
  workspace,
}) => {
  const dependentFeatureServicesColumns = [
    {
      name: 'Feature Service',
      render: (fs: FeatureServiceFCO) => (
        <Link to={`../../${workspace}/feature-services/${fs.name}`}>
          <FCOIconAndName type={FCOType.FEATURE_SERVICE} name={fs.name ?? ''} description={fs.description} />
        </Link>
      ),
    },
    {
      name: 'Last Updated',
      render: (fs: FeatureServiceFCO) => TectonDateTimeFormat(moment(fs[FCOFields.LAST_MODIFIED_DATE])),
    },
    {
      name: 'Owner',
      render: (fs: FeatureServiceFCO) => (
        <div style={{ width: '200px' }}>
          <Avatar name={fs[FCOFields.OWNER] ?? ''} showName />
        </div>
      ),
    },
    {
      name: 'Created',
      render: (fs: FeatureServiceFCO) => TectonDateTimeFormat(moment(fs[FCOFields.CREATED_DATE])),
    },
  ];

  return (
    <Table
      compressed
      items={featureServices ?? []}
      columns={dependentFeatureServicesColumns}
      data-testid="related-fcos-table"
      emptyPrompt={
        <EmptyPrompt
          orientation="horizontal"
          title={<>No Related Feature Services</>}
          body={
            <>
              <p>
                <FCOIconAndName name={fco.name ?? ''} type={fco[FCOFields.FCO_TYPE]} /> is not served by any Feature
                Service. This means that it is not currently used by any live feature serving pipeline.
              </p>
            </>
          }
          actions={<Button variant="primaryAction" label={'Learn More'} />}
        />
      }
    />
  );
};

export const RelatedDatasetsTable: FC<{
  fco: AnyFCO;
  idToFcoMap: Record<string, AnyFCO>;
  workspace?: string;
  isLoading: boolean;
  data: DatasetFCO[];
}> = ({ fco, idToFcoMap, workspace, data, isLoading }) => {
  if (isLoading) {
    return <Loading />;
  }

  const matchingDataSets = data.filter((dataset) => {
    return dataset.featureServiceName === fco.name;
  });

  const dependentFeatureServicesColumns = [
    {
      name: 'Dataset',
      render: (dataset: DatasetFCO) => (
        <Link to={`../../${workspace}/datasets/${dataset.name}`}>
          <FCOIconAndName type={FCOType.DATASET} name={dataset.name ?? ''} description={dataset.description} />
        </Link>
      ),
    },
    {
      name: 'Type',
      render: (dataset: DatasetFCO) => dataset.type,
    },
    {
      name: 'Feature View',
      render: (featureView: FeatureViewFCO) => {
        const isModified = !Object.keys(idToFcoMap).includes(featureView.id);
        return <FCOIconAndName name={featureView.name ?? ''} type={FCOType.FEATURE_VIEW} isModified={isModified} />;
      },
    },
    {
      name: 'Feature Service',
      render: (featureService: FeatureServiceFCO) => {
        const isModified = !Object.keys(idToFcoMap).includes(featureService.id);
        return (
          <FCOIconAndName name={featureService.name ?? ''} type={FCOType.FEATURE_SERVICE} isModified={isModified} />
        );
      },
    },
    {
      name: 'Created',
      render: (dataset: DatasetFCO) => TectonDateTimeFormat(moment(dataset[FCOFields.CREATED_DATE])),
    },
  ] as EuiBasicTableColumn<DatasetFCO>[];

  return (
    <Table
      compressed
      items={matchingDataSets ?? []}
      columns={dependentFeatureServicesColumns}
      emptyPrompt={
        <EmptyPrompt
          orientation="horizontal"
          title={<>No Related Datasets</>}
          body={
            <>
              <p>The data from this Feature Service has never been stored in a Dataset.</p>
              <p>
                Datasets allow for conveniently saving feature data that can be used for model training, experiment
                reproducibility, and analysis.
              </p>
            </>
          }
          actions={
            <LinkIcon
              linkName={'Learn More'}
              href={'https://docs.tecton.ai/docs/sdk-reference/data-wrappers/Dataset'}
              iconType={BookIcon}
              target="_blank"
            />
          }
        />
      }
    />
  );
};

const RelatedFCOsTable: FC<RelatedFeatureViewsTableProps> = ({
  fco,
  type,
  workspace,
  featureViews,
  featureServices,
}) => {
  if (type === FCOType.FEATURE_VIEW) {
    return <RelatedFeatureViewsTable fco={fco} featureViews={featureViews ?? []} workspace={workspace} />;
  }

  return <RelatedFeatureServicesTable fco={fco} featureServices={featureServices ?? []} workspace={workspace} />;
};

export default RelatedFCOsTable;
