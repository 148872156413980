import React, { FC, useState } from 'react';
import { FeatureViewFCO, FeatureViewServingStatusSummaryWithId } from '../../../core/types/fcoTypes';
import { ServingStatusEvent, ServingStatusPanelTableDatum } from '../../servingStatus/types';
import { scaleTime } from 'd3-scale';
import { getEarliestStartDate, restructureFeatureViews } from './ServingStatus/utils';
import ServingStatusAxisRow from './ServingStatus/AxisLabel';
import ServingStatusHeaderRow from './ServingStatus/HeaderRow';
import ServingStatusTitleAndSelector from './ServingStatus/TitleAndSelector';
import ServingStatusTableRow from './ServingStatus/TableRow';
import { TimePeriodSelectorOptions } from '@tecton/ComponentRedesign';

import { EmptyPrompt } from '@tecton/ComponentRedesign';

interface TectonServingStatusSvgProps {
  width: number;
  children: React.ReactNode;
}

export const TectonServingStatusSvg: FC<TectonServingStatusSvgProps> = ({ children, width }) => {
  return (
    <svg width={width} height={24}>
      {children}
    </svg>
  );
};

interface ServingStateChartProps {
  featureViews: FeatureViewFCO[];
  attemptSummary: FeatureViewServingStatusSummaryWithId[];
  isLoading?: boolean;
  isError?: boolean;
  hideTitle?: boolean;
}

const ServingStateChart: FC<ServingStateChartProps> = ({ featureViews, attemptSummary, isLoading, isError }) => {
  const structuredDataForTable = restructureFeatureViews(featureViews, attemptSummary, '');

  const [timeSelectorOption, setTimeSelectorOption] = useState<TimePeriodSelectorOptions>({
    label: 'All Time',
    value: undefined,
  });

  const [maxWidth, setMaxWidth] = useState<number>(500);

  const [startDate, setStartDate] = useState(getEarliestStartDate(structuredDataForTable));

  const [highlightedBar, setHighlightedBar] = useState<
    | { id: string; rowIndex: number; event?: ServingStatusEvent; streamingDatum?: ServingStatusPanelTableDatum }
    | undefined
  >(undefined);

  const xScale = scaleTime().domain([startDate, new Date()]).range([0, maxWidth]);

  if (structuredDataForTable.length === 0 || structuredDataForTable[0].events.length === 0) {
    return (
      <EmptyPrompt variant="search" title={<>No Materialization Data</>} body={<>No materialization data exists.</>} />
    );
  }

  if (isLoading) {
    return (
      <>
        <ServingStatusTitleAndSelector disabled timeSelectorOption={timeSelectorOption} hideTitle />
        <EmptyPrompt title={<>Materialization Loading</>} body={<></>} orientation="horizontal" variant="loading" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <ServingStatusTitleAndSelector disabled timeSelectorOption={timeSelectorOption} hideTitle />
        <EmptyPrompt
          title={<>Materialization Data Error</>}
          body={<>No Feature Views have materialization enabled.</>}
          orientation="horizontal"
          variant="storm"
        />
      </>
    );
  }

  if (attemptSummary.length === 0) {
    return (
      <>
        <ServingStatusTitleAndSelector disabled timeSelectorOption={timeSelectorOption} hideTitle />
        <EmptyPrompt
          title={<>Materialization Not Available</>}
          body={<>No Feature Views have materialization enabled.</>}
          orientation="horizontal"
        />
      </>
    );
  }

  return (
    <>
      <ServingStatusTitleAndSelector
        timeSelectorOption={timeSelectorOption}
        setTimeSelectorOption={setTimeSelectorOption}
        setStartDate={setStartDate}
        structuredDataForTable={structuredDataForTable}
        data-testid="serving-status-title"
        hideTitle
      />
      <ServingStatusHeaderRow setMaxWidth={setMaxWidth} data-testid="serving-status-header" />
      <ServingStatusAxisRow startDate={startDate} data-testid="serving-status-axis" />
      <>
        {structuredDataForTable.map((datum, rowIndex) => {
          return (
            <ServingStatusTableRow
              datum={datum}
              startDate={startDate}
              xScale={xScale}
              rowIndex={rowIndex}
              maxWidth={maxWidth}
              highlightedBar={highlightedBar}
              setHighlightedBar={setHighlightedBar}
              structuredDataForTable={structuredDataForTable}
              data-testid="serving-status-row"
            />
          );
        })}
      </>
    </>
  );
};

export default ServingStateChart;
