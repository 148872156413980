import styled from '@emotion/styled';
import React, { FC } from 'react';

interface CardProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  noScroll?: boolean;
  centerBody?: boolean;
  flex?: boolean;
}

const CardWrapper = styled.div<CardProps>`
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: ${({ theme }) => theme.padding.xl};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: ${({ theme }) => theme.border.thin};
  ${({ noScroll }) => !noScroll && `max-height: 400px`};
  display: ${({ flex }) => (flex ? 'flex' : 'grid')};
  grid-template-rows: auto 1fr;
  gap: ${({ theme }) => theme.padding.l};
`;

const CardTitle = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const CardBody = styled.div<CardProps>`
  overflow: auto;
  // Make sure the content is center from the card horizontally and vertically
  ${({ centerBody }) =>
    centerBody &&
    `
    display: flex;
    align-items: center;
    justify-content: center;`};
`;

const TectonCard: FC<CardProps> = ({ children, title, noScroll, centerBody, flex = false }) => {
  return (
    <CardWrapper data-testid="card" flex={flex} noScroll={noScroll}>
      {title && <CardTitle>{title}</CardTitle>}
      <CardBody centerBody={centerBody}>{children}</CardBody>
    </CardWrapper>
  );
};

export default TectonCard;
