import React, { FC } from 'react';
import { AnyFCO, FCOFields } from '../../../core/types/fcoTypes';
import { Avatar, EnabledDisabledBadge, useTectonTheme, FCOCard } from '@tecton/ComponentRedesign';
import { TectonDateTimeFormat } from './utils';
import moment from 'moment-timezone';
import styled from '@emotion/styled';

interface FCOMetadataCardProps {
  fco: AnyFCO;
}

const FCOMetadataCard: FC<FCOMetadataCardProps> = ({ fco }) => {
  const { theme } = useTectonTheme();

  const Monospace = styled.span`
    font-family: ${theme.font.familyCode};
  `;

  const items = [
    {
      title: <>ID</>,
      content: <>{fco[FCOFields.ID]}</>,
    },

    {
      title: <>Owner</>,
      content: fco.owner ? <Avatar name={fco.owner} showName /> : <></>,
    },
    {
      title: <>Created</>,
      content: TectonDateTimeFormat(moment(fco[FCOFields.CREATED_DATE])),
    },
    {
      title: <>Last Updated By</>,
      content: fco[FCOFields.LAST_MODIFIED_BY] && <Avatar name={fco[FCOFields.LAST_MODIFIED_BY]} showName />,
    },
    {
      title: <>Last Updated</>,
      content: TectonDateTimeFormat(moment(fco[FCOFields.LAST_MODIFIED_DATE])),
    },
    {
      title: <>Defined In</>,
      content: <Monospace>{fco[FCOFields.SOURCE_FILE_NAME]}</Monospace>,
    },
    {
      title: <>Prevent Destroy</>,
      content: <EnabledDisabledBadge enabled={fco[FCOFields.PREVENT_DESTROY] ?? false} />,
    },
  ];

  return <FCOCard title="Metadata" items={items} data-testid="fco-metadata-card" />;
};

export default FCOMetadataCard;
