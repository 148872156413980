export default {
  themeName: 'EUI_THEME_AMSTERDAM',
  colors: {
    ghost: '#FFF',
    ink: '#000',
    primary: '#07C',
    accent: '#F04E98',
    success: '#00BFB3',
    warning: '#FEC514',
    danger: '#BD271E',
    emptyShade: '#FFF',
    lightestShade: '#F1F4FA',
    lightShade: '#D3DAE6',
    mediumShade: '#98A2B3',
    darkShade: '#69707D',
    darkestShade: '#343741',
    fullShade: '#000',
    body: '#f7f8fc',
    highlight: '#fff9e8',
    disabled: '#ABB4C4',
    disabledText: '#a2abba',
    shadow: '#000',
    primaryText: '#006bb8',
    accentText: '#ba3d76',
    successText: '#007871',
    warningText: '#83650a',
    dangerText: '#bd271e',
    text: '#343741',
    title: '#1a1c21',
    subduedText: '#646a77',
    link: '#006bb8',
  },
  base: 16,
  size: {
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '12px',
    base: '16px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
    xxxl: '48px',
    xxxxl: '64px',
  },
  border: {
    color: '#D3DAE6',
    width: {
      thin: '1px',
      thick: '2px',
    },
    radius: {
      medium: '6px',
      small: '4px',
    },
    thin: '1px solid #D3DAE6',
    thick: '2px solid #D3DAE6',
    editable: '2px dotted #D3DAE6',
  },
  font: {
    family: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif",
    familyCode: "'Roboto Mono', Menlo, Courier, monospace",
    familySerif: 'Georgia, Times, Times New Roman, serif',
    featureSettings: "'calt' 1, 'kern' 1, 'liga' 1",
    defaultUnits: 'rem',
    baseline: 4,
    lineHeightMultiplier: 1.5,
    scale: {
      xxxs: 0.5625,
      xxs: 0.6875,
      xs: 0.75,
      s: 0.875,
      m: 1,
      l: 1.375,
      xl: 1.6875,
      xxl: 2.125,
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    body: {
      scale: 's',
      weight: 'regular',
    },
    title: {
      weight: 'bold',
    },
  },
  animation: {
    extraFast: '90ms',
    fast: '150ms',
    normal: '250ms',
    slow: '350ms',
    extraSlow: '500ms',
    bounce: 'cubic-bezier(.34, 1.61, .7, 1)',
    resistance: 'cubic-bezier(.694, .0482, .335, 1)',
  },
  breakpoint: {
    xl: 1200,
    l: 992,
    m: 768,
    s: 575,
    xs: 0,
  },
  levels: {
    toast: 9000,
    modal: 8000,
    mask: 6000,
    navigation: 6000,
    menu: 2000,
    header: 1000,
    flyout: 1000,
    maskBelowHeader: 1000,
    content: 0,
  },
  focus: {
    color: 'currentColor',
    width: '2px',
    transparency: 0.1,
    backgroundColor: 'rgba(0,119,204,0.1)',
  },
};
