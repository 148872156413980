import React, { Component, ErrorInfo, ReactNode } from 'react';
import { FlexGroup, FlexItem, Text, Link } from '@tecton/ComponentRedesign';
import { ReactComponent as ErrorBoundaryIcon } from '@svg/error-boundary.svg';
import styled from '@emotion/styled';

type BoundarySize = 'm' | 'l';

interface ErrorBoundaryProps {
  children?: ReactNode;
  size?: BoundarySize;
}

interface ErrorBoundaryVisualProps {
  size?: BoundarySize;
}

interface State {
  hasError: boolean;
}

const StyledFlexGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.xl};
  height: 100%;
  width: 100%;
  text-align: center; /* Center text inside FlexItems */
`;

export const ErrorBoundaryVisual = ({ size = 'm' }: ErrorBoundaryVisualProps) => {
  return (
    <StyledFlexGroup data-testid="error-boundary">
      <FlexItem grow={0}>
        <FlexGroup direction="column" alignItems="center" justifyContent="center">
          <FlexItem grow={0}>
            <ErrorBoundaryIcon />
          </FlexItem>
          <FlexItem>
            <Text fontSize="m" fontWeight="semiBold">
              Unable to load
            </Text>
          </FlexItem>
          <FlexItem>
            <div style={{ maxWidth: '360px' }}>
              <Text>
                {size === 'l' ? (
                  <>
                    Please{' '}
                    <Link
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      refresh
                    </Link>{' '}
                    this page or return <Link href="/">home</Link>. If the problem persists, contact your Tecton
                    Administrator or email <Link href="mailto:support@tecton.ai">support@tecton.ai</Link>.
                  </>
                ) : (
                  'There was an error. Contact your administrator for help.'
                )}
              </Text>
            </div>
          </FlexItem>
        </FlexGroup>
      </FlexItem>
    </StyledFlexGroup>
  );
};

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryVisual size={this.props.size} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
