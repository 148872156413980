import React, { FC } from 'react';
import { ServingState } from '../../../../types/tecton_proto/data/serving_status';
import { ServingStatusEvent, ServingStatusPanelTableDatum } from '../../../servingStatus/types';
import { useTectonTheme, Health, HealthProps } from '@tecton/ComponentRedesign';
import { TectonDateTimeFormat } from '../utils';
import moment from 'moment-timezone';
import styled from '@emotion/styled';

interface ServingStateTooltipProps {
  batchEvent?: ServingStatusEvent;
  streamingDatum?: ServingStatusPanelTableDatum;
  x: number;
  y: number;
}

const ServingStatusTooltip: FC<ServingStateTooltipProps> = ({ batchEvent, streamingDatum, x, y }) => {
  const { theme } = useTectonTheme();

  const height = 50;
  const width = 500;

  const statusMap: Record<ServingState, HealthProps> = {
    [ServingState.SERVING_STATE_DISABLED]: { variant: 'warning', label: 'Disabled' },
    [ServingState.SERVING_STATE_ERROR]: { variant: 'failure', label: 'Error' },
    [ServingState.SERVING_STATE_NOT_ENOUGH_DATA]: { variant: 'neutral', label: 'Not Enough Data' },
    [ServingState.SERVING_STATE_OK]: { variant: 'healthy', label: 'Okay' },
    [ServingState.SERVING_STATE_PENDING]: { variant: 'neutral', label: 'Pending' },
    [ServingState.SERVING_STATE_RUNNING]: { variant: 'active', label: 'Running' },
    [ServingState.SERVING_STATE_UNSPECIFIED]: { variant: 'warning', label: 'Unspecified' },
  };

  const TooltipBody = styled.div`
    padding: ${theme.padding.s} ${theme.padding.xs};
    background-color: ${theme.colors.emptyShade};
    box-shadow: ${theme.shadow.l};
  `;

  const ResultDescription = styled.div`
    font-size: ${theme.font.fontSizes.xs};
    padding: ${theme.padding.xs} ${theme.padding.l};
  `;

  if (batchEvent) {
    return (
      <>
        <foreignObject
          x={x - width / 2}
          width={width}
          height={height}
          y={y}
          style={{ overflow: 'visible', pointerEvents: 'none', backgroundColor: 'white' }}
        >
          <TooltipBody>
            <Health {...statusMap[batchEvent.status]} />
            <ResultDescription>
              Between {TectonDateTimeFormat(moment(batchEvent.startDate))} and{' '}
              {TectonDateTimeFormat(moment(batchEvent.endDate))}
            </ResultDescription>
            <div>
              <svg
                height={15}
                width={30}
                style={{ position: 'absolute', bottom: '-30px', left: 'calc(50%)', overflow: 'visible' }}
              >
                <polygon points="-15,0 0,15 15,0" fill="white" />
              </svg>
            </div>
          </TooltipBody>
        </foreignObject>
      </>
    );
  }

  if (!streamingDatum || streamingDatum.streamingStatus === undefined) {
    return <></>;
  }

  return (
    <>
      <foreignObject
        x={x - width / 2}
        width={width}
        height={height}
        y={y}
        style={{ overflow: 'visible', pointerEvents: 'none', backgroundColor: 'white' }}
      >
        <TooltipBody>
          <Health {...statusMap[streamingDatum.streamingStatus]} />
          <ResultDescription>
            Since {TectonDateTimeFormat(moment(streamingDatum.streamingStartDate))}. Updated{' '}
            {TectonDateTimeFormat(moment(streamingDatum.streamingMostRecentDate))}
          </ResultDescription>
          <div>
            <svg
              height={15}
              width={30}
              style={{ position: 'absolute', bottom: '-30px', left: 'calc(50%)', overflow: 'visible' }}
            >
              <polygon points="-15,0 0,15 15,0" fill="white" />
            </svg>
          </div>
        </TooltipBody>
      </foreignObject>
    </>
  );
};

export default ServingStatusTooltip;
