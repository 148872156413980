import React, { FC, useState } from 'react';

import { ButtonEmpty, Flyout, Code } from '@tecton/ComponentRedesign';
import { ReactComponent as CodeDocumentIcon } from '@svg/code-document.svg';

const ViewDefinitionFlyout: FC<{ type: any; definition: any }> = ({ type, definition }) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);

  return (
    <>
      <ButtonEmpty
        onClick={() => {
          setIsFlyoutOpen(true);
        }}
        iconType={CodeDocumentIcon}
      >
        View Definition
      </ButtonEmpty>
      {isFlyoutOpen && (
        <Flyout
          title={type}
          onClose={() => {
            setIsFlyoutOpen(false);
          }}
        >
          <Code code={definition.toString()} />
        </Flyout>
      )}
    </>
  );
};

export default ViewDefinitionFlyout;
