import styled from '@emotion/styled';

export const gridRow = (theme: any) => {
  return styled.div`
    display: grid;
    grid-template-columns: 260px 1fr 128px;
    overflow: visible;
    background-color: #fff;

    :last-of-type {
      border-bottom-left-radius: ${theme.border.radius.medium};
      border-bottom-right-radius: ${theme.border.radius.medium};
    }
  `;
};
