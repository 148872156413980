import React, { FC } from 'react';
import { FlexGroupWrapper } from '../StyledComponents';
import { FlexItem } from '../NeedsDesign';
import styled from '@emotion/styled';

type ItemOrientation = 'vertical' | 'horizontal';

interface TectonTitledItemProps {
  title: React.ReactNode;
  content: React.ReactNode;
  orientation?: ItemOrientation;
}

//  Let's make sure we get an ellipsis for the content.
const FlexItemStyled = styled(FlexItem)`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
  display: unset;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const HorizontalTitledItem: FC<TectonTitledItemProps> = ({ title, content }) => {
  return (
    <>
      <FlexGroupWrapper direction="row" gap="l" alignItems="flexStart" justifyContent="flexEnd">
        <FlexItemStyled>{title}</FlexItemStyled>
        <FlexItemStyled>{content}</FlexItemStyled>
      </FlexGroupWrapper>
    </>
  );
};

const VerticalTitledItem: FC<TectonTitledItemProps> = ({ title, content }) => {
  return (
    <>
      <FlexGroupWrapper direction="column" gap="l" alignItems="flexStart" justifyContent="flexEnd">
        <FlexItemStyled>{title}</FlexItemStyled>
        <FlexItemStyled>{content}</FlexItemStyled>
      </FlexGroupWrapper>
    </>
  );
};

const TectonTitledItem: FC<TectonTitledItemProps> = ({ title, content, orientation = 'horizontal' }) => {
  if (orientation === 'horizontal') {
    return <HorizontalTitledItem title={title} content={content} data-testid="titled-item" />;
  }
  return <VerticalTitledItem title={title} content={content} data-testid="titled-item" />;
};

export default TectonTitledItem;
