import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes } from '../routes';
import { replaceWorkspace } from '../../utils/route-utils';
import WorkspaceUtils from '../../utils/workspace-utils';
import { useLocation, useNavigate } from 'react-router';
import useGlobalState from '../../shared/useGlobalState';
import { OnboardingSidebarState } from '../../utils/onboarding/onboarding-util';
import onboardingStateToRoutesMapping from '../../feature/onboarding/utils/onboardingStateToRouteMapping';
import { useUserSettings } from '../../components/context/UserSettingsContext';

/*
  This components make sure you end up in a workspace route.
  For example.
  If a the URL is /app or /app/, this component will send you to /app/repo/prod/home or what ever
  ACL workspace provides you
*/

const useRedirectToDashboardIfNeeded = (hasFinishedInitializing: boolean) => {
  const aclsWorkspace = useSelector(WorkspaceUtils.selectedWorkspaceFromLocalStorage);
  const legacyWorkspace = useSelector(WorkspaceUtils.selectWorkspace);
  const { isPublicFreeTrial } = useUserSettings();

  const onBoardingState: OnboardingSidebarState[] | undefined = useSelector((state: any) => {
    return state?.onBoarding?.onBoardingStates;
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { isGuidedOnboardingEnabled } = useGlobalState();
  const noWorkspacePath = `/repo/false/home`;
  const selectWorkspacePath = `/select-a-workspace`;
  const defaultPublicFreeTrialWorkspacePath = `/repo/prod/home`;

  useEffect(() => {
    if (hasFinishedInitializing) {
      // use legacy route with we have isGuidedOnboardingEnabled
      if (isGuidedOnboardingEnabled) {
        const pathName = replaceWorkspace(Routes.dashboard, legacyWorkspace);
        const currentState = onBoardingState?.find((step) => step.isCurrentState === true);

        if (noWorkspacePath === pathName && currentState) {
          /* Redirect user to root path when we get /repo/false/home as a path*/
          const route = onboardingStateToRoutesMapping(currentState);
          if (currentState && route) {
            navigate(route);
          } else {
            // Redirect them to configure data bricks. If they have configured it already,
            // the onboarding redirect should take care of the correct state.
            navigate(Routes.dataPlatformConfiguration);
          }
        } else {
          navigate(pathName);
        }
      } else if (location && location.pathname === '/') {
        const pathName = replaceWorkspace(Routes.dashboard, aclsWorkspace);

        if (aclsWorkspace === false) {
          if (isPublicFreeTrial) {
            // We redirect public free trail users to "prod" so they don't have to select a workspace.
            navigate(defaultPublicFreeTrialWorkspacePath);
          } else {
            /* We can't find a workspace in localStorage and in the URL. Send the user to /app/choose-a-workspace path */
            navigate(selectWorkspacePath);
          }
        } else {
          navigate(pathName);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFinishedInitializing]);
};

export default useRedirectToDashboardIfNeeded;
