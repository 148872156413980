import { TimeInterval, format, timeDay, timeFormat, timeHour, timeMonth, timeSecond, timeYear } from 'd3';
import { Moment } from 'moment-timezone';
export const TectonIntegerFormat = format(',');
export const TectonPercentageFormat = format('.2%');

export const TectonDateFormat = (moment: Moment) => moment.format('YYYY-MM-DD');
export const TectonDateTimeFormat = (moment: Moment) => moment.format('YYYY-MM-DD HH:MM:SS');
export const TectonDateTimeFormatWithTz = (date: Moment, timezone: string) => {
  if (date && timezone) {
    return date.clone().tz(timezone).format('YYYY-MM-DD HH:MM:SS');
  } else {
    return date ? date.clone().format('YYYY-MM-DD HH:MM:SS') : 'n/a';
  }
};
export const hashForAvatars = (name: string) => {
  let hash = 0;
  for (let i = 0, len = name.length; i < len; i++) {
    const character = name.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash % 7);
};

export const getInitialsFromString = (name: string) => {
  const splitName = name
    .split(/[ _-]+/)
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join('')
    .toUpperCase();

  return splitName;
};

export const SecondsSince = (date: Date) => {
  return new Date().getTime() / 1000 - date.getTime() / 1000;
};

export const TectonTimeSince = (date: Date) => {
  const secondsToAgoString = (seconds: number): string => {
    const intervals: [number, string][] = [
      [Math.floor(seconds / 31536000), 'year'],
      [Math.floor((seconds % 31536000) / 2592000), 'month'],
      [Math.floor((seconds % 2592000) / 86400), 'day'],
      [Math.floor((seconds % 86400) / 3600), 'hour'],
      [Math.floor((seconds % 3600) / 60), 'minute'],
      [Math.floor(seconds % 60), 'second'],
    ];

    const largestInterval = intervals.find(([amount]) => amount > 0);

    if (largestInterval) {
      const [amount, unit] = largestInterval;
      return `${amount} ${unit}${amount !== 1 ? 's' : ''} ago`;
    }

    return 'just now';
  };

  return secondsToAgoString(SecondsSince(date));
};

export const DateFormattingForTimeExtent = (seconds: number) => {
  function getTimeFormatter(seconds: number): (date: Date) => string {
    // Calculate the time interval based on the number of seconds
    let interval: TimeInterval = timeSecond;
    if (seconds >= 31536000) {
      // 1 year = 31536000 seconds
      interval = timeYear;
    } else if (seconds >= 2592000) {
      // 1 month = 2592000 seconds
      interval = timeMonth;
    } else if (seconds >= 86400) {
      // 1 day = 86400 seconds
      interval = timeDay;
    } else if (seconds >= 3600) {
      // 1 hour = 3600 seconds
      interval = timeHour;
    }

    // Return the appropriate D3 time formatter function
    return timeFormat(getTimeFormatString(interval));
  }

  function getTimeFormatString(interval: d3.TimeInterval): string {
    switch (interval) {
      case timeYear:
        return '%Y'; // Year
      case timeMonth:
        return '%B %Y'; // Month and Year
      case timeDay:
        return '%A %B %e'; // Month and Day, Year
      case timeHour:
        return '%H:%M'; // Month, Day, Year, 24-hour time
      default:
        return '%H:%M'; // Default format
    }
  }

  return getTimeFormatter(seconds);
};
