import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';

interface MonospaceProps {
  children: React.ReactNode;
}

const Monospace: FC<MonospaceProps> = ({ children }) => {
  const { theme } = useTectonTheme();

  const MonospaceStyle = styled.span`
    font-family: ${theme.font.familyCode};
  `;

  return <MonospaceStyle data-testid="monospace">{children}</MonospaceStyle>;
};

export default Monospace;
