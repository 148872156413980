import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { EuiDescriptionList } from '@elastic/eui';
import styled from '@emotion/styled';

interface TectonDescriptionListProps {
  items: {
    title: NonNullable<React.ReactNode>;
    description: NonNullable<React.ReactNode>;
  }[];
}

const TectonDescriptionList: FC<TectonDescriptionListProps> = ({ items }) => {
  const { theme } = useTectonTheme();

  const StyledDescriptionList = styled(EuiDescriptionList)`
    font-size: ${theme.font.fontSizes.s};

    .euiDescriptionList__title {
      font-size: ${theme.font.fontSizes.s};
      font-weight: ${theme.font.weight.medium};
    }

    .euiDescriptionList__description {
      font-weight: ${theme.font.weight.regular};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;

  return <StyledDescriptionList listItems={items} type="responsiveColumn" data-testid="description-list" />;
};

export default TectonDescriptionList;
