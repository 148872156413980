import { EuiPopover, ExclusiveUnion } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { DatePicker, FilterButton, Selectable } from '@tecton/ComponentRedesign';
import moment from 'moment';
import {
  EuiSelectableGroupLabelOption,
  EuiSelectableLIOption,
  EuiSelectableOptionCheckedType,
} from '@elastic/eui/src/components/selectable/selectable_option';
import { differenceWith, isEqual, uniq } from 'lodash';
import { ReactComponent as ChevronDown } from '@svg/chevronRight.svg';

// Local state util functions for combo
export const countActiveSelectionsForComboFilterChecklist = (items: ComboSelectorChecklistOption[]) => {
  return items.filter((item) => item.checked === 'on').length;
};

export const countActiveSelectionsForHierarchicalComboFilterChecklist = (
  items: CategoricalSelectorHierarchicalOption[]
) => {
  return items.filter((item) => item.checked === 'on' && item.parent).length;
};

export const countActiveSelectionsForComboFilterDateRange = (state: ComboFilterDateRangeState) => {
  return [
    state.selected.start.getTime() !== state.default.start.getTime(),
    state.selected.end.getTime() !== state.default.end.getTime(),
  ].filter((isActive) => isActive).length;
};

export const checkIfComboFilterChecklistHasActiveCategories = (items: ComboSelectorChecklistOption[]) => {
  return items.filter((item) => item.checked === 'on').length > 0;
};

export const checkIfComboFilterDateRangeHasActiveCategories = (state: ComboFilterDateRangeState) => {
  return (
    state.selected.start.getTime() !== state.default.start.getTime() ||
    state.selected.end.getTime() !== state.default.end.getTime()
  );
};

export type ComboFilterSelector = {
  hidden?: boolean;
  label: string;
  numFilters: number | undefined;
  hasActiveFilters: boolean;
  numActiveFilters: number | undefined;
  filterElement: React.ReactNode;
};

export type ComboFilterCategoricalState = {
  value: boolean;
  label: string;
  checked?: 'on' | 'off' | undefined;
}[];

export type ComboFilterDateRangeState = {
  default: {
    start: Date;
    end: Date;
  };
  selected: {
    start: Date;
    end: Date;
  };
};

export type ComboFilterSingleFilterState = ComboFilterCategoricalState | ComboFilterDateRangeState;

export type ComboFilterState = Record<string, ComboFilterSingleFilterState>;

interface ComboFilterProps {
  buttonLabel: string;
  filterTypes: Record<string, ComboFilterSelector>;
  isOpen: boolean | undefined;
  selectedFilter: string | undefined;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedFilter: (selectedFilter: string | undefined) => void;
  numberOfActiveFilters: number | undefined;
  clearAllFilterCallback?: () => void;
  resetActiveFilter: (key: string) => void;
}

// Review each of these styled components with Design and tokenize / remove where possible
const ItemWrapper = styled.div<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 12px 6px 16px',
  cursor: 'pointer',
  backgroundColor: isActive ? theme.colors.body : theme.colors.emptyShade,
  color: isActive ? theme.colors.fullShade : theme.colors.text,
  fontWeight: theme.font.weight.regular,
  borderRadius: theme.border.radius.medium,
  lineHeight: '20px', // 142.857%
  ':hover': {
    backgroundColor: theme.colors.body,
    color: theme.colors.title,
  },
}));

const IndentedTag = styled.div<{ parent?: boolean }>(({ theme, parent }) => ({
  paddingLeft: parent ? theme.size.xl : theme.size.s,
  fontWeight: parent ? theme.font.weight.medium : theme.font.weight.bold,
  borderBottom: parent ? 'none' : theme.border.thin,
}));

const Notification = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.fullShade,
  color: theme.colors.emptyShade,

  textAlign: 'center',
  borderRadius: '32px', // One-off design specification
  padding: `1px ${theme.padding.s}`, // One-off design specification
  fontSize: theme.font.fontSizes.xs,
}));

// Review which of these need to be used
const ComboFilterPopoverContentWrapper = styled.div<{ isPanelOpen: boolean }>`
  display: grid;
  transition: width 0.2s ease-in-out;
  width: ${({ isPanelOpen }) => (isPanelOpen ? '640px' : '240px')}; // Review this widths with design
  grid-template-rows: ${({ isPanelOpen }) => (isPanelOpen ? '1fr auto' : 'auto')};
  overflow: hidden;
`;

const ComboFilterActivePanelWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 16px;
  border-left: ${({ theme }) => theme.border.thin};

  .euiFormControlLayoutCustomIcon {
    padding: ${({ theme }) => theme.padding.s};
    stroke: ${({ theme }) => theme.colors.text};
  }

  .euiFieldSearch--compressed {
    padding-left: 40px; //One OFF Style for combofilter input
  }
`;

const ComboFiltersContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  max-height: 75vh;
  overflow: hidden;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 220px;
  padding: 6px 12px 6px 16px;
  min-height: 360px;
  overflow-y: auto;
`;

const ComboFiltersFooterWrapper = styled.div`
  padding: 12px 16px 8px 16px;

  display: flex;
  padding: 12px 16px 8px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-top: ${({ theme }) => theme.border.thin};
`;

const ComboFiltersListWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  return <BodyWrapper>{children}</BodyWrapper>;
};

const ComboFilterCategoryListItem: FC<{
  option: ComboFilterSelector;
  onClick: () => void;
  isActive?: boolean;
}> = ({ option, onClick, isActive = false }) => {
  return (
    <ItemWrapper onClick={onClick} isActive={isActive}>
      <div>{option.label}</div>
      <div>{option.hasActiveFilters ? <Notification>{option.numActiveFilters}</Notification> : <ChevronDown />}</div>
    </ItemWrapper>
  );
};

const Reset = styled.div<{ filterCount: number }>(({ theme, filterCount }) => ({
  color: filterCount === 0 ? theme.colors.subduedText : theme.colors.darkestShade,
  fontSize: theme.font.fontSizes.xs,
  fontWeight: theme.font.weight.semiBold,
  lineHeight: '20px', // 166.667%
  cursor: filterCount === 0 ? 'not-allowed' : 'pointer', // Use 'not-allowed' for disabled cursor
  height: '32px',
  display: 'flex',
  alignItems: 'center',
}));

const ComboFiltersFooter: FC<{ filterCount?: number; resetCallback?: () => void }> = ({
  filterCount = 0,
  resetCallback,
}) => {
  const linkText = filterCount === 0 ? 'Reset All Filters' : `Reset All Filters (${filterCount})`;

  return (
    <Reset
      filterCount={filterCount}
      onClick={() => {
        resetCallback && resetCallback();
      }}
    >
      {linkText}
    </Reset>
  );
};

const ComboSelectedFilterFooter: FC<{ filterCount?: number; resetCallback?: () => void }> = ({
  filterCount,
  resetCallback,
}) => {
  return (
    <Reset
      filterCount={filterCount || 0}
      onClick={() => {
        filterCount && filterCount > 0 && resetCallback && resetCallback();
      }}
    >
      Reset
    </Reset>
  );
};

// Overall ComboFilter Component
export const ComboFilter: FC<ComboFilterProps> = ({
  filterTypes,
  numberOfActiveFilters,
  clearAllFilterCallback,
  isOpen,
  selectedFilter,
  setIsOpen,
  setSelectedFilter,
  resetActiveFilter,
}) => {
  const isPanelOpen = !!selectedFilter;

  const filterKeys = Object.keys(filterTypes);
  const displayedFilterKeys = filterKeys.filter((key) => !filterTypes[key]?.hidden);

  const hasActiveFilters = numberOfActiveFilters && numberOfActiveFilters > 0 ? true : false;

  return (
    <EuiPopover
      button={
        <FilterButton
          isDisabled={displayedFilterKeys.length === 0}
          isActive={hasActiveFilters}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          quantity={hasActiveFilters ? numberOfActiveFilters : filterKeys.length}
          hasActiveFilters={hasActiveFilters}
          data-testid="combo-filter-button"
        />
      }
      data-testid="combo-filter-content"
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      panelPaddingSize="none"
    >
      <ComboFilterPopoverContentWrapper isPanelOpen={isPanelOpen} key={'filtersDiv'} className="strokeCurrentColor">
        <ComboFiltersContent>
          <ComboFiltersListWrapper>
            {displayedFilterKeys.map((key) => {
              const option = filterTypes[key];

              return (
                <ComboFilterCategoryListItem
                  option={option}
                  onClick={() => {
                    setSelectedFilter(key);
                  }}
                  key={key}
                  isActive={key === selectedFilter}
                />
              );
            })}
          </ComboFiltersListWrapper>
          {isPanelOpen && selectedFilter && filterTypes && filterTypes[selectedFilter] && (
            <ComboFilterActivePanelWrapper>
              <div></div>
              <div>{filterTypes[selectedFilter].filterElement}</div>
            </ComboFilterActivePanelWrapper>
          )}
        </ComboFiltersContent>
        <ComboFiltersFooterWrapper>
          <ComboFiltersFooter filterCount={numberOfActiveFilters ?? 0} resetCallback={clearAllFilterCallback} />
          {selectedFilter && (
            <ComboSelectedFilterFooter
              filterCount={
                selectedFilter && filterTypes[selectedFilter] ? filterTypes[selectedFilter].numActiveFilters : 0
              }
              resetCallback={() => {
                selectedFilter && resetActiveFilter(selectedFilter);
              }}
            />
          )}
        </ComboFiltersFooterWrapper>
      </ComboFilterPopoverContentWrapper>
    </EuiPopover>
  );
};

// Date Range Selector subcomponent below

interface ComboFilterDateRangeSelectorProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const ComboFilterDatePanelLayout = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const ComboFilterDatePanelInterior = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.padding.l};
  flex-direction: column;
`;

export const ComboFilterDateRangeSelector: FC<ComboFilterDateRangeSelectorProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <ComboFilterDatePanelLayout>
      <ComboFilterDatePanelInterior>
        <DatePicker
          label="Start"
          date={moment(startDate)}
          setDate={(date) => {
            setStartDate(date.toDate());
          }}
          dateAndTime
        />
        <DatePicker
          label="End"
          date={moment(endDate)}
          setDate={(date) => {
            setEndDate(date.toDate());
          }}
          dateAndTime
        />
      </ComboFilterDatePanelInterior>
    </ComboFilterDatePanelLayout>
  );
};

// Checklist Selector subcomponent below

interface OrderProperty {
  order?: number;
}

interface ComboFilterOptionInterface {
  label: string;
  checked?: string;
}

export type ComboFilterOptionType = ExclusiveUnion<
  EuiSelectableGroupLabelOption<ComboFilterOptionInterface & OrderProperty>,
  EuiSelectableLIOption<ComboFilterOptionInterface & OrderProperty>
>;

export interface ComboSelectorChecklistOption {
  label: string;
  checked?: EuiSelectableOptionCheckedType;
  value?: any;
}

interface ComboFilterChecklistProps {
  items: ComboSelectorChecklistOption[];
  setItems: (items: ComboFilterOptionType[]) => void;
  single?: boolean;
  sort?: boolean;
  searchable?: boolean;
  name?: string;
  renderOption?: (item: ComboFilterOptionType) => React.ReactNode;
}

const checkedSort = (a: ComboFilterOptionType, b: ComboFilterOptionType) => {
  if (a.checked === 'on' && b.checked !== 'on') {
    return -1;
  } else if (a.checked !== 'on' && b.checked === 'on') {
    return 1;
  } else {
    // If both 'a' and 'b' have the same "checked" state, sort alphabetically by the "label" property
    return a.label.localeCompare(b.label);
  }
};

export const ComboFilterCountOptions = ['0', '1', '2', '3 - 5', '6 - 10', '> 10'];

export const ComboFilterChecklist: FC<ComboFilterChecklistProps> = ({
  items,
  single = false,
  sort = false,
  searchable = false,
  name,
  setItems,
  renderOption,
}: ComboFilterChecklistProps) => {
  return (
    <Selectable
      searchable={searchable}
      options={sort ? items.sort(checkedSort) : items}
      data-testid={`selector-list-${name}`}
      changeCallback={(newOptions) => {
        setItems(newOptions);
      }}
      key={`selector-list-${name}`}
      singleSelect={single}
      renderOption={renderOption}
    />
  );
};

export interface CategoricalSelectorHierarchicalOption {
  label: string;
  parent?: string;
  checked?: 'on' | 'off';
  indeterminate?: boolean | undefined;
}

interface CategoricalSelectorHierarchicalProps {
  options: CategoricalSelectorHierarchicalOption[];
  onChange: (selectedOption: CategoricalSelectorHierarchicalOption[]) => void;
}

// Hierarchical (tag) Selector subcomponent below
export const ComboFilterHierarchicalChecklist: FC<CategoricalSelectorHierarchicalProps> = ({ options, onChange }) => {
  return (
    <Selectable
      key={'hierarchical-selector'}
      options={options}
      changeCallback={(optionsReturned) => {
        const selectedOption = differenceWith(
          optionsReturned,
          options,
          isEqual
        )[0] as CategoricalSelectorHierarchicalOption;

        if (selectedOption.parent) {
          // A child value was selected
          const altered = optionsReturned.map((option) => {
            if (option.label === selectedOption.parent) {
              const stateOfAllChildrenUnderParent = uniq(
                optionsReturned
                  .filter(
                    (filteredOption: CategoricalSelectorHierarchicalOption) =>
                      filteredOption.parent === selectedOption.parent
                  )
                  .map((mappedOption) => mappedOption.checked)
              );

              if (stateOfAllChildrenUnderParent.length > 1) {
                return {
                  ...option,
                  indeterminate: true,
                };
              }
              return {
                ...option,
                indeterminate: false,
                checked: stateOfAllChildrenUnderParent[0],
              };
            }
            return option;
          });
          onChange(altered);
        } else {
          const altered = options.map((option) => {
            if (option.label === selectedOption.label) {
              return {
                ...option,
                checked: selectedOption.checked,
                indeterminate: false,
              };
            }
            if (option.parent === selectedOption.label) {
              return {
                ...option,
                checked: selectedOption.checked,
                indeterminate: false,
              };
            }
            return { ...option, indeterminate: false };
          });
          onChange(altered as CategoricalSelectorHierarchicalOption[]);
        }
      }}
      renderOption={(option: CategoricalSelectorHierarchicalOption) => {
        return (
          <>
            <IndentedTag parent={option.parent ? true : false}>
              <div style={{ pointerEvents: 'none' }}>{option.label}</div>
            </IndentedTag>
          </>
        );
      }}
    />
  );
};
