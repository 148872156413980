import { EuiModal } from '@elastic/eui';
import styled from '@emotion/styled';
import { Global } from '@emotion/react';
import React, { FC } from 'react';

import { FlexGroup, FlexItem } from '../../_eui_components';
import { Button, ButtonVariant } from '@tecton/ComponentRedesign';

interface TectonModalProps {
  title?: string;
  body: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  variant?: 'positive' | 'negative' | 'neutral' | 'search';
  cancelLabel?: string;
  confirmLabel?: string;
  confirmVariant?: ButtonVariant;
  isConfirmButtonLoading?: boolean;
  maxWidth?: boolean | number | string;
  ref?: React.RefObject<HTMLDivElement> | null;
}

const SearchModalContainer = styled(EuiModal)(({ theme }) => ({
  display: 'flex',
  width: '600px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.padding.m,
  flexShrink: 0,
  borderRadius: theme.border.radius.small,
  background: theme.colors.emptyShade,
  boxShadow: theme.shadow.xl,
  paddingBottom: theme.padding.l,
  marginTop: '20vh',

  '.euiModal__closeIcon': {
    insetInlineEnd: '12px',
    insetBlockStart: 'calc(50% - 12px)',
  },

  [`@media (max-width: ${theme.breakpoint.m}px)`]: {
    position: 'relative',
  },
}));

const SearchModalContent = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.padding.s};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.xl};
  align-self: stretch;
  color: ${({ theme }) => theme.colors.mediumShade};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
`;

const SearchModal: FC<TectonModalProps> = ({ body, onCancel, ref }) => {
  return (
    <SearchModalContainer onClose={onCancel}>
      <SearchModalContent ref={ref}>{body}</SearchModalContent>
    </SearchModalContainer>
  );
};

const ModalContainer = styled.div`
  display: flex;

  padding: ${({ theme }) => theme.padding.xl};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.l};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.border.radius.small};
  background: ${({ theme }) => theme.colors.emptyShade};
  box-shadow: ${({ theme }) => theme.shadow.xl};
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const ModalContent = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.padding.s};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.xl};
  align-self: stretch;
  color: ${({ theme }) => theme.colors.mediumShade};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.l};
  align-self: stretch;
`;

const TectonModal: FC<TectonModalProps> = ({
  title,
  body,
  onCancel,
  onConfirm,
  variant = 'neutral',
  cancelLabel,
  confirmLabel,
  confirmVariant,
  isConfirmButtonLoading,
  maxWidth,
  ref = null,
}) => {
  if (variant === 'search') {
    return (
      <>
        <Global
          styles={{
            '.euiOverlayMask': {
              alignItems: 'flex-start',
            },
          }}
        />
        <SearchModal title={title} body={body} onCancel={onCancel} onConfirm={onConfirm} ref={ref} />
      </>
    );
  }

  let ActionButtons = <></>;
  switch (variant) {
    case 'neutral':
      ActionButtons = (
        <>
          <FlexItem grow={false}>
            <Button
              label={confirmLabel ?? 'Confirm'}
              onClick={onConfirm}
              variant={confirmVariant ?? 'primaryAction'}
              isLoading={isConfirmButtonLoading}
            />
          </FlexItem>
        </>
      );
      break;
    case 'negative':
      ActionButtons = (
        <>
          <FlexItem grow={false}>
            <Button
              label={confirmLabel ?? 'Confirm'}
              onClick={onConfirm}
              variant={confirmVariant ?? 'destructiveAction'}
              isLoading={isConfirmButtonLoading}
            />
          </FlexItem>
          <FlexItem grow={false}>
            <Button label={cancelLabel ?? 'Cancel'} onClick={onCancel} variant={'emptyAction'} />
          </FlexItem>
        </>
      );
      break;
    case 'positive':
      ActionButtons = (
        <>
          <FlexItem grow={false}>
            <Button
              label={confirmLabel ?? 'Confirm'}
              onClick={onConfirm}
              variant={confirmVariant ?? 'primaryAction'}
              isLoading={isConfirmButtonLoading}
            />
          </FlexItem>
          <FlexItem grow={false}>
            <Button label={cancelLabel ?? 'Cancel'} onClick={onCancel} variant={'emptyAction'} />
          </FlexItem>
        </>
      );
      break;
  }

  return (
    <EuiModal onClose={onCancel} data-testid="modal" maxWidth={maxWidth}>
      <ModalContainer ref={ref}>
        <div>
          <ModalTitle>{title}</ModalTitle>
          <ModalContent>{body}</ModalContent>
        </div>
        <ModalFooter>
          <FlexGroup direction="rowReverse" alignItems="flexEnd" gutterSize="m" wrap>
            {ActionButtons}
          </FlexGroup>
        </ModalFooter>
      </ModalContainer>
    </EuiModal>
  );
};

export default TectonModal;
