import { FC } from 'react';
import { Badge } from '@tecton/ComponentRedesign';

interface PassedFailedBadgeProps {
  passed: boolean;
}

const PassedFailedBadge: FC<PassedFailedBadgeProps> = ({ passed }) => {
  if (passed) {
    return <Badge data-testid="passed-badge" variant={'success'} label={'Passed'} />;
  }
  return <Badge variant={'danger'} data-testid="failed-badge" label={'Failed'} />;
};
export default PassedFailedBadge;
