import moment from 'moment-timezone';
import _ from 'lodash';

export function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    return (images[item.replace('./', '')] = r(item));
  });
  return images;
}

const AWS_REGION = 's3-us-west-2.amazonaws.com';
export default function httpUrlFromS3Path(path) {
  const regex = /s3:\/\/(.*?)\/(.*)/gm;
  const groups = regex.exec(path);
  const bucket = groups[1];
  const key = groups[2];
  const url = `https://${AWS_REGION}/${bucket}/${key}`;
  return url;
}

const PROMETHEUS_URL = 'http://prometheus-service.monitoring:9090/api/v1/query_range?';
export function prometheusQueryRangeUrl(start, end, step, query) {
  return `${PROMETHEUS_URL}start=${start}&end=${end}&step=${step}&query=${encodeURIComponent(query)}`;
}

export function randombetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function convertToSeconds(multiplier, unit) {
  if (unit === 'hours') {
    return 60 * 60 * multiplier;
  }
  if (unit === 'minutes') {
    return 60 * multiplier;
  }
  if (unit === 'days') {
    return 60 * 60 * 24 * multiplier;
  }
  return multiplier;
}

export function convertSecondsTo(unit, seconds) {
  if (unit === 'hours') {
    return seconds / 60 / 60;
  }
  if (unit === 'minutes') {
    return seconds / 60;
  }
  if (unit === 'days') {
    return seconds / 60 / 60 / 24;
  }
  return seconds;
}

export function secondsToHumanFriendlyString(seconds, topLevel = true) {
  if (isNaN(seconds)) {
    return '';
  }

  if (seconds === 0) {
    return topLevel ? '0s' : '';
  } else if (seconds < 60) {
    return seconds + 's';
  } else if (seconds < 3600) {
    return Math.floor(seconds / 60) + 'm ' + secondsToHumanFriendlyString(Math.round(seconds) % 60, false);
  } else if (seconds < 3600 * 24) {
    return Math.floor(seconds / 3600) + 'h ' + secondsToHumanFriendlyString(seconds % 3600, false);
  } else {
    return Math.floor(seconds / (3600 * 24)) + 'd ' + secondsToHumanFriendlyString(seconds % (3600 * 24), false);
  }
}

export const getStoreTypeLabel = (fv, field, types) => {
  const param = _.get(fv, '_materializationParams.' + field);
  if (param) {
    const keys = Object.keys(param);
    // This is a whitelist for offline types. When we add more types, this list needs to be updated.

    if (Array.isArray(keys)) {
      const key = keys.find((key) => {
        return types.includes(key);
      });
      return key ? key : '-';
    }

    return '-';
  }

  return '-';
};

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function titleCase(str, splitter = ' ') {
  const ret = str
    .toLowerCase()
    .split(splitter)
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ');
  return ret;
}

export const durationToSeconds = (duration) => {
  const durationInt = parseInt(duration);
  const durationUnit = duration.replace(durationInt.toString(), '');
  return moment.duration(durationInt, durationUnit).asSeconds();
};

export const getUrlParameters = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return params;
};

export const removeParameterFromUrl = (parameter) => {
  let url = new URL(window.location.href);
  url.searchParams.delete(parameter);
  // So we don't refresh the page and just update the URL
  window.history.replaceState('', '', url);
};
