import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTectonTheme, Button } from '@tecton/ComponentRedesign';
import { EuiFlyout } from '@elastic/eui';

interface FlyoutProps {
  title: React.ReactNode;
  children: React.ReactNode;
  cancel?: () => void;
  refresh?: () => void;
  save?: () => void;
  onClose?: () => void;
}

const TectonFlyout: FC<FlyoutProps> = ({
  title,
  children,
  cancel,
  refresh,
  save,
  onClose = () => {
    return;
  },
}) => {
  const { theme } = useTectonTheme();

  const FlyoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
  `;

  const FlyoutHeader = styled.div`
    padding: ${theme.padding.xl};
    border-bottom: 1px solid ${theme.colors.lightShade};
    width: 100%;
    font-size: ${theme.font.headingSizes[3]};
    line-height: ${theme.font.headingLineHeights[3]};
    font-weight: ${theme.font.weight.medium};
  `;

  const FlyoutContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    padding: ${theme.padding.xl};
  `;

  const FlyoutFooter = styled.div`
    background-color: ${theme.colors.lightestShade};
    width: 100%;
    display: flex;
    padding: ${theme.padding.l} ${theme.padding.xl};
    align-items: flex-start;
    gap: ${theme.padding.l};
    align-self: stretch;
  `;

  const FlyoutCancelContainer = styled.div``;

  const FlyoutConfirmContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: ${theme.padding.l};
    flex: 1 0 0;
  `;

  return (
    <EuiFlyout onClose={onClose} data-testid="flyout" maskProps={{ headerZindexLocation: 'above' }}>
      <FlyoutContainer>
        <FlyoutHeader>{title}</FlyoutHeader>
        <FlyoutContent>{children}</FlyoutContent>
        <FlyoutFooter>
          <FlyoutCancelContainer>
            {cancel && <Button label={'Cancel'} variant={'emptyAction'} onClick={cancel} />}
          </FlyoutCancelContainer>
          <FlyoutConfirmContainer>
            {refresh && <Button label={'Refresh'} variant={'primaryAction'} />}
            {save && <Button label={'Save'} variant={'emptyAction'} />}
          </FlyoutConfirmContainer>
        </FlyoutFooter>
      </FlyoutContainer>
    </EuiFlyout>
  );
};

export default TectonFlyout;
