import React, { FC } from 'react';
import styled from '@emotion/styled';

import { FlexGroupWrapper, FlexItem, Icon, IconType } from '@tecton/ComponentRedesign';

export interface TectonSubtitleProps {
  linkName: string;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  iconType: IconType;
}

const Link = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.fullShade};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  cursor: pointer;
  word-break: keep-all;
  white-space: nowrap;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkIcon: FC<TectonSubtitleProps> = ({ linkName, href, target, iconType }) => {
  return (
    <LinkWrapper>
      {href && (
        <FlexGroupWrapper gap="s" direction="row" alignItems="center">
          {iconType && (
            <FlexItem>
              <Icon type={iconType} />
            </FlexItem>
          )}
          <FlexItem>
            <Link {...(target ? { target: target } : {})} href={href}>
              {linkName}
            </Link>
          </FlexItem>
        </FlexGroupWrapper>
      )}
    </LinkWrapper>
  );
};

export default LinkIcon;
