import React, { FC } from 'react';
import styled from '@emotion/styled';

interface ApplicationLayoutProps {
  leftSideContent: React.ReactNode;
  rightSideContent: React.ReactNode;
}

const AppWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  margin: 0,
  padding: 0,
  backgroundColor: theme.colors?.body,
  display: 'grid',
  gap: theme.padding?.m,
  gridTemplateColumns: 'auto 1fr',
}));

const ApplicationLayout: FC<ApplicationLayoutProps> = ({ leftSideContent, rightSideContent }) => {
  return (
    <AppWrapper data-testid="tecton-application-layout">
      <div>{leftSideContent}</div>
      <div>{rightSideContent}</div>
    </AppWrapper>
  );
};

export default ApplicationLayout;
